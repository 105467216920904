// Client/src/components/Masters.js
import React, { useState, useEffect, useMemo } from 'react';
import { Package, Search, Plus, Edit2, Eye, Trash2 } from 'lucide-react';
import { fetchMasters,fetchPlatforms } from '../../routes/AppRoutes';
import './Masters.css';

const Masters = () => {
  
  
  const [selectedPlatform, setSelectedPlatform] = useState('Flipkart');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(''); // 'platform' or 'product'
  const [editingItem, setEditingItem] = useState(null);
  const [platforms, setPlatforms] = useState([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');



  useEffect(() => {
    const loadPlatforms = async () => {
      try {
        const response = await fetchPlatforms();
        if (response.success && Array.isArray(response.data)) {
          setPlatforms(response.data.map(p => p.platform_name));
          if (!selectedPlatform) {
            setSelectedPlatform(response.data[0]?.platform_name || "");
          }
        }
      } catch (error) {
        console.error('Error loading platforms:', error);
        console.error('Failed to load platforms. Please refresh the page.');
      }
    };

    loadPlatforms();
    
    if (selectedPlatform) {
      fetchProductsForPlatform(selectedPlatform);
    }
  }, [selectedPlatform]);

  

  const fetchProductsForPlatform = async (platform) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchMasters(platform);
      if (!response) throw new Error('No response received');
      
      if (response.success && response.data) {
        const actualData = Array.isArray(response.data) ? response.data : response.data.data;
        if (!actualData) throw new Error('Invalid data format');
        setProducts(actualData);
      } else {
        throw new Error(response?.message || 'Failed to fetch data');
      }
    } catch (err) {
      setError(err.message || 'Failed to fetch products');
      setProducts([]);
    } finally {
      setLoading(false);
    }
  };

  // Helper function to safely render cell content
  const renderCellContent = (value) => {
    if (value === null || value === undefined) return '';
    if (typeof value === 'object') return JSON.stringify(value);
    return value.toString();
  };

  const renderDynamicTable = () => {
    if (!products || products.length === 0) {
      return <div className="no-data">No products found</div>;
    }

    // Updated filter to exclude 'Status' and handle 'Last Updated'
    const columns = Object.keys(products[0])
      .filter(key => !['id', 'type', '__typename'].includes(key))
      .map(key => {
        // Rename columns if needed
        if (key === 'Last Updated') return 'Date and Time';
        return key;
      });

    return (
      <div className="table-scroll">
        <table className="products-table">
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column}>
                  {column
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}
                </th>
              ))}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product, index) => (
              <tr key={product.id || index}>
                {columns.map((column) => (
                  <td key={column}>
                    {column === 'Date and Time' 
                      ? new Date(product['Last Updated']).toLocaleString()
                      : renderCellContent(product[column])}
                  </td>
                ))}
                <td className="action-buttons">
                  <button 
                    className="action-btn edit" 
                    onClick={() => handleEdit(product)}
                    title="Edit"
                  >
                    <Edit2 size={16} />
                  </button>
                  <button 
                    className="action-btn view"
                    onClick={() => handleView(product)}
                    title="View"
                  >
                    <Eye size={16} />
                  </button>
                  <button 
                    className="action-btn delete"
                    onClick={() => handleDelete(product)}
                    title="Delete"
                  >
                    <Trash2 size={16} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  // Update the filtered products to handle objects
  const filteredProducts = useMemo(() => {
    return products.filter(product => {
      const searchLower = debouncedSearchTerm.toLowerCase();
      return Object.entries(product).some(([key, value]) => {
        if (value === null || value === undefined) return false;
        const stringValue = typeof value === 'object' 
          ? JSON.stringify(value) 
          : String(value);
        return stringValue.toLowerCase().includes(searchLower);
      });
    });
  }, [products, debouncedSearchTerm]);

  const handleAddPlatform = () => {
    setModalType('platform');
    setEditingItem(null);
    setIsModalOpen(true);
  };

  const handleAddProduct = () => {
    setModalType('product');
    setEditingItem(null);
    setIsModalOpen(true);
  };

  const handleEdit = (product) => {
    setModalType('product');
    setEditingItem({
      ...product,
      type: 'product'
    });
    setIsModalOpen(true);
  };

  const handleView = (product) => {
    setModalType('view');
    setEditingItem({
      ...product,
      type: 'view'
    });
    setIsModalOpen(true);
  };

  const handleDelete = (product) => {
    if (window.confirm(`Are you sure you want to delete ${product.productName}?`)) {
      const updatedProducts = products.filter(p => p.id !== product.id);
      setProducts(updatedProducts);
    }
  };

  // Enhanced form handling
  const handleSubmit = async (formData, type) => {
    try {
      if (type === 'platform') {
        const newPlatform = {
          id: platforms.length + 1,
          name: formData.name,
          products: 0,
          lastSync: new Date().toLocaleString(),
          description: formData.description
        };
        setPlatforms([...platforms, newPlatform]);
      } else if (type === 'product') {
        const newProduct = {
          id: products.length + 1,
          ...formData,
          lastUpdated: new Date().toLocaleDateString()
        };
        setProducts([...products, newProduct]);
      }
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  // Enhanced Modal component
  const Modal = ({ onClose, type, editingItem }) => {
    const [formData, setFormData] = useState(() => 
      editingItem || {
        sku: '',
        productName: '',
        category: '',
        targetPrice: '',
        currentPrice: '',
        stock: '',
        status: 'In Stock'
      }
    );

    // Cleanup effect
    useEffect(() => {
      const handleEscape = (e) => {
        if (e.key === 'Escape') onClose();
      };
      
      document.addEventListener('keydown', handleEscape);
      return () => document.removeEventListener('keydown', handleEscape);
    }, [onClose]);

    // Reset form when type or editingItem changes
    useEffect(() => {
      setFormData(editingItem || {
        sku: '',
        productName: '',
        category: '',
        targetPrice: '',
        currentPrice: '',
        stock: '',
        status: 'In Stock'
      });
    }, [type, editingItem]);

    const isViewMode = type === 'view';

    const handleSubmitForm = (e) => {
      e.preventDefault();
      handleSubmit(formData, type);
    };

    const renderFormFields = () => {
      switch (type) {
        case 'view':
          return (
            <div className="view-mode">
              <div className="form-row">
                <div className="form-group">
                  <label>SKU</label>
                  <div className="view-value">{editingItem.sku}</div>
                </div>
                <div className="form-group">
                  <label>Category</label>
                  <div className="view-value">{editingItem.category}</div>
                </div>
              </div>
              <div className="form-group">
                <label>Product Name</label>
                <div className="view-value">{editingItem.productName}</div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>Target Price</label>
                  <div className="view-value">₹{editingItem.targetPrice}</div>
                </div>
                <div className="form-group">
                  <label>Current Price</label>
                  <div className="view-value">₹{editingItem.currentPrice}</div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>Stock</label>
                  <div className="view-value">{editingItem.stock}</div>
                </div>
              </div>
              <div className="form-group">
                <label>Last Updated</label>
                <div className="view-value">
                  {editingItem['Last Updated'] ? new Date(editingItem['Last Updated']).toLocaleString() : ''}
                </div>
              </div>
            </div>
          );

        case 'platform':
          return (
            <>
              <div className="form-group">
                <label>Platform Name*</label>
                <input
                  type="text"
                  value={formData.name || ''}
                  onChange={e => setFormData({...formData, name: e.target.value})}
                  placeholder="Enter platform name"
                  required
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  value={formData.description || ''}
                  onChange={e => setFormData({...formData, description: e.target.value})}
                  placeholder="Enter platform description"
                />
              </div>
            </>
          );

        case 'product':
          return (
            <>
              <div className="form-row">
                <div className="form-group">
                  <label>SKU*</label>
                  <input
                    type="text"
                    value={formData.sku || ''}
                    onChange={e => setFormData({...formData, sku: e.target.value})}
                    placeholder="Enter SKU"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Category*</label>
                  <select
                    value={formData.category || ''}
                    onChange={e => setFormData({...formData, category: e.target.value})}
                    required
                  >
                    <option value="">Select Category</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Wearables">Wearables</option>
                    <option value="Mobiles">Mobiles</option>
                    <option value="Smart Home">Smart Home</option>
                    <option value="Groceries">Groceries</option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <label>Product Name*</label>
                <input
                  type="text"
                  value={formData.productName || ''}
                  onChange={e => setFormData({...formData, productName: e.target.value})}
                  placeholder="Enter product name"
                  required
                />
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>Target Price*</label>
                  <input
                    type="number"
                    value={formData.targetPrice || ''}
                    onChange={e => setFormData({...formData, targetPrice: e.target.value})}
                    placeholder="Enter target price"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Current Price*</label>
                  <input
                    type="number"
                    value={formData.currentPrice || ''}
                    onChange={e => setFormData({...formData, currentPrice: e.target.value})}
                    placeholder="Enter current price"
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>Stock*</label>
                  <input
                    type="number"
                    value={formData.stock || ''}
                    onChange={e => setFormData({...formData, stock: e.target.value})}
                    placeholder="Enter stock quantity"
                    required
                  />
                </div>
              </div>
            </>
          );

        default:
          return null;
      }
    };

    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>
            {isViewMode ? 'Product Details' : 
             editingItem ? 'Edit' : 'Add New'} {type !== 'view' ? type : ''}
          </h2>
          {isViewMode ? (
            <>
              {renderFormFields()}
              <div className="modal-actions">
                <button type="button" onClick={onClose}>Close</button>
              </div>
            </>
          ) : (
            <form onSubmit={handleSubmitForm}>
              {renderFormFields()}
              <div className="modal-actions">
                <button type="button" onClick={onClose}>Cancel</button>
                <button type="submit">Save</button>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  return (
    <div className="masters-container">
      <div className="masters-header">
        <h2>Platform Masters</h2>
        <button className="add-platform-btn" onClick={handleAddPlatform}>
          <Plus size={20} />
          Add New Platform
        </button>
      </div>

      <div className="platform-cards">
        {platforms.map((platformName, index) => (
          <div
            key={index}
            className={`platform-card compact ${selectedPlatform === platformName ? 'active' : ''}`}
            onClick={() => setSelectedPlatform(platformName)}
          >
            <div className="platform-icon">
              <Package size={20} />
            </div>
            <div className="platform-info">
              <h3>{platformName}</h3>
            </div>
          </div>
        ))}
      </div>

      {selectedPlatform && (
        <div className="products-section">
          <div className="products-header">
            <div className="search-bar">
              <Search size={20} />
              <input
                type="text"
                placeholder="Search products..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <button className="add-product-btn" onClick={handleAddProduct}>
              <Plus size={20} />
              Add Product
            </button>
          </div>

          {loading ? (
            <div className="loading-state">
              <div className="loading-spinner"></div>
              <p>Loading products...</p>
            </div>
          ) : error ? (
            <div className="error-state">
              <p>{error}</p>
            </div>
          ) : (
            renderDynamicTable()
          )}
        </div>
      )}

      {isModalOpen && (
        <Modal
          type={modalType}
          editingItem={editingItem}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Masters;