import React, { useState, useEffect } from 'react';
import { AlertCircle, CheckCircle, Info, AlertTriangle, X } from 'lucide-react';

const LoadingCard = ({ onComplete, action }) => {
  const [progress, setProgress] = useState(0);
  

  useEffect(() => {
   
    const startTime = Date.now();
    const maxDuration = 1000;

    const progressInterval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const newProgress = Math.min((elapsedTime / maxDuration) * 100, 100);
      setProgress(newProgress);
      

      if (elapsedTime >= maxDuration) {
        
        clearInterval(progressInterval);
        onComplete?.();
      }
    }, 16);

    return () => {
      
      clearInterval(progressInterval);
    };
  }, [onComplete]);

  return (
    <div className="bg-gray-900/80 backdrop-blur-xl rounded-2xl shadow-2xl overflow-hidden p-8 w-96">
      {/* Animated Background */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-500/10 via-purple-500/10 to-blue-500/10"></div>
      
      {/* Hexagon Grid Background */}
      <div className="absolute inset-0 opacity-10">
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGgxMHYxMEgwek0xMCAxMGgxMHYxMEgxMHoiIGZpbGw9IiNmZmYiIGZpbGwtb3BhY2l0eT0iMC4xIi8+PC9zdmc+')] bg-repeat"></div>
      </div>

      <div className="relative z-10 space-y-6">
        {/* Futuristic Loading Animation */}
        <div className="flex justify-center">
          <div className="relative w-24 h-24">
            {/* Outer rotating ring */}
            <div className="absolute inset-0 rounded-full border-4 border-blue-500/30 animate-spin-slow"></div>
            
            {/* Inner rotating ring */}
            <div className="absolute inset-2 rounded-full border-4 border-t-purple-500 border-r-transparent border-b-transparent border-l-transparent animate-spin"></div>
            
            {/* Center pulsing circle */}
            <div className="absolute inset-4 rounded-full bg-gradient-to-br from-blue-400 to-purple-500 animate-pulse"></div>
            
            {/* Progress percentage */}
            <div className="absolute inset-0 flex items-center justify-center">
              <span className="text-white font-bold text-xl">
                {Math.round(progress)}%
              </span>
            </div>
          </div>
        </div>

        {/* Enhanced Progress Bar */}
        <div className="relative h-2 bg-gray-800 rounded-full overflow-hidden">
          <div 
            className="absolute inset-y-0 left-0 bg-gradient-to-r from-blue-500 to-purple-500 transition-all duration-300 ease-out rounded-full"
            style={{ width: `${progress}%` }}
          >
            {/* Animated shine effect */}
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/30 to-transparent animate-shine"></div>
          </div>
        </div>

        {/* Loading Message */}
        <div className="text-center space-y-2">
          <div className="text-white/80 font-medium">
            <span className="inline-block animate-pulse">
              Initializing {action}...
            </span>
          </div>
          <p className="text-gray-400 text-sm">
            Preparing your experience
          </p>
        </div>
      </div>

      {/* Decorative Elements */}
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-blue-500 via-purple-500 to-blue-500"></div>
    </div>
  );
};

const SuccessCard = ({ onSuccess, action }) => {
  
  
  useEffect(() => {
    
    const timer = setTimeout(() => {
      
      onSuccess?.();
    }, 1500);

    return () => {
      
      clearTimeout(timer);
    };
  }, [onSuccess]);

  const getSuccessMessage = () => {
    switch (action) {
      case 'login':
        return 'Access granted. Welcome back!';
      case 'logout':
        return 'Successfully disconnected. Stay safe!';
      default:
        return 'Operation completed successfully.';
    }
  };

  return (
    <div className="bg-gray-900/80 backdrop-blur-xl rounded-2xl shadow-2xl overflow-hidden p-8 w-96 relative">
      {/* Animated Background */}
      <div className="absolute inset-0 bg-gradient-to-br from-green-500/10 via-emerald-500/10 to-green-500/10"></div>
      
      {/* Success Animation Container */}
      <div className="relative z-10 space-y-6">
        <div className="flex justify-center">
          <div className="relative w-24 h-24">
            {/* Outer success ring */}
            <div className="absolute inset-0 rounded-full border-4 border-green-500/30"></div>
            
            {/* Expanding success ring */}
            <div className="absolute inset-0 rounded-full border-4 border-green-500/50 animate-success-ring"></div>
            
            {/* Success icon container */}
            <div className="absolute inset-2 rounded-full bg-gradient-to-br from-green-400 to-emerald-500 flex items-center justify-center animate-success-pop">
              <CheckCircle className="w-12 h-12 text-white" />
            </div>
            
            {/* Particle effects */}
            <div className="absolute inset-0">
              {[...Array(8)].map((_, i) => (
                <div
                  key={i}
                  className="absolute w-2 h-2 bg-green-400 rounded-full animate-success-particle"
                  style={{
                    top: '50%',
                    left: '50%',
                    transform: `rotate(${i * 45}deg) translateY(-40px)`,
                    animationDelay: `${i * 0.1}s`,
                  }}
                />
              ))}
            </div>
          </div>
        </div>

        {/* Success Message */}
        <div className="text-center space-y-3">
          <h2 className="text-2xl font-bold bg-gradient-to-r from-green-400 to-emerald-500 bg-clip-text text-transparent animate-success-text">
            Success!
          </h2>
          <p className="text-gray-300 text-sm animate-success-text" style={{ animationDelay: '0.2s' }}>
            {getSuccessMessage()}
          </p>
        </div>
      </div>

      {/* Bottom border */}
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-green-500 via-emerald-500 to-green-500"></div>
    </div>
  );
};

const ErrorCard = ({ onClose, errorMessage }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 2000);
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="bg-gray-900/80 backdrop-blur-xl rounded-2xl shadow-2xl overflow-hidden p-8 w-96 relative">
      {/* Animated Background */}
      <div className="absolute inset-0 bg-gradient-to-br from-red-500/10 via-rose-500/10 to-red-500/10"></div>
      
      {/* Error Animation Container */}
      <div className="relative z-10 space-y-6">
        <div className="flex justify-center">
          <div className="relative w-24 h-24">
            {/* Outer error ring */}
            <div className="absolute inset-0 rounded-full border-4 border-red-500/30"></div>
            
            {/* Pulsing error ring */}
            <div className="absolute inset-0 rounded-full border-4 border-red-500/50 animate-error-pulse"></div>
            
            {/* Error icon container */}
            <div className="absolute inset-2 rounded-full bg-gradient-to-br from-red-400 to-rose-500 flex items-center justify-center animate-error-shake">
              <AlertCircle className="w-12 h-12 text-white" />
            </div>
            
            {/* Warning flashes */}
            <div className="absolute inset-0">
              {[...Array(4)].map((_, i) => (
                <div
                  key={i}
                  className="absolute inset-0 rounded-full border-2 border-red-400 animate-error-flash"
                  style={{ animationDelay: `${i * 0.2}s` }}
                />
              ))}
            </div>
          </div>
        </div>

        {/* Error Message */}
        <div className="text-center space-y-3">
          <h2 className="text-2xl font-bold bg-gradient-to-r from-red-400 to-rose-500 bg-clip-text text-transparent animate-error-text">
            Error
          </h2>
          <p className="text-gray-300 text-sm animate-error-text" style={{ animationDelay: '0.2s' }}>
            {errorMessage || "Something went wrong. Please try again."}
          </p>
        </div>
      </div>

      {/* Bottom border */}
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-red-500 via-rose-500 to-red-500"></div>
    </div>
  );
};

const AlertCard = ({ 
  onClose, 
  onConfirm, 
  message, 
  type = 'info', 
  title,
  showConfirmButton = false 
}) => {
  useEffect(() => {
    // Only auto-close if there's no confirmation button
    if (!showConfirmButton) {
      const timer = setTimeout(onClose, 3000);
      return () => clearTimeout(timer);
    }
  }, [onClose, showConfirmButton]);

  const getAlertStyles = () => {
    switch (type) {
      case 'warning':
        return {
          bg: 'from-yellow-500/10 via-amber-500/10 to-yellow-500/10',
          border: 'from-yellow-500 via-amber-500 to-yellow-500',
          icon: <AlertTriangle className="w-12 h-12 text-yellow-500" />,
          title: title || 'Warning',
          titleGradient: 'from-yellow-400 to-amber-500'
        };
      case 'success':
        return {
          bg: 'from-green-500/10 via-emerald-500/10 to-green-500/10',
          border: 'from-green-500 via-emerald-500 to-green-500',
          icon: <CheckCircle className="w-12 h-12 text-emerald-500" />,
          title: title || 'Success',
          titleGradient: 'from-green-400 to-emerald-500'
        };
      case 'error':
        return {
          bg: 'from-red-500/10 via-rose-500/10 to-red-500/10',
          border: 'from-red-500 via-rose-500 to-red-500',
          icon: <AlertCircle className="w-12 h-12 text-red-500" />,
          title: title || 'Error',
          titleGradient: 'from-red-400 to-rose-500'
        };
      case 'info':
        return {
          bg: 'from-blue-500/10 via-cyan-500/10 to-blue-500/10',
          border: 'from-blue-500 via-cyan-500 to-blue-500',
          icon: <Info className="w-12 h-12 text-blue-500" />,
          title: title || 'Information',
          titleGradient: 'from-blue-400 to-cyan-500'
        };
      default:
        return {
          bg: 'from-blue-500/10 via-cyan-500/10 to-blue-500/10',
          border: 'from-blue-500 via-cyan-500 to-blue-500',
          icon: <Info className="w-12 h-12 text-blue-500" />,
          title: title || 'Information',
          titleGradient: 'from-blue-400 to-cyan-500'
        };
    }
  };

  const styles = getAlertStyles();

  return (
    <div className={`fixed ${showConfirmButton ? 'inset-0 flex items-center justify-center' : 'top-4 right-4'} z-50 animate-slide-in`}>
      <div className={`bg-gray-900/80 backdrop-blur-xl rounded-lg shadow-2xl overflow-hidden p-6 ${showConfirmButton ? 'max-w-lg w-full mx-4' : 'w-96'} relative`}>
        {/* Animated Background */}
        <div className={`absolute inset-0 bg-gradient-to-br ${styles.bg}`}></div>
        
        {/* Content */}
        <div className="relative z-10 flex flex-col items-center space-y-4">
          {/* Icon */}
          <div className="flex-shrink-0 bg-gray-800/50 rounded-full p-2">
            {styles.icon}
          </div>

          {/* Text Content */}
          <div className="flex-1 min-w-0 text-center">
            <h3 className={`text-lg font-semibold bg-gradient-to-r ${styles.titleGradient} bg-clip-text text-transparent`}>
              {styles.title}
            </h3>
            <p className="mt-1 text-gray-300 text-sm whitespace-pre-line">
              {message}
            </p>
          </div>

          {/* Buttons */}
          {showConfirmButton && (
            <div className="flex space-x-4 mt-6">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-600 hover:bg-gray-500 rounded-lg text-white transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={onConfirm}
                className="px-4 py-2 bg-amber-600 hover:bg-amber-500 rounded-lg text-white transition-colors"
              >
                Confirm
              </button>
            </div>
          )}

          {/* Close Button (only show for non-confirmation alerts) */}
          {!showConfirmButton && (
            <button
              onClick={onClose}
              className="absolute top-2 right-2 text-gray-400 hover:text-white transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          )}
        </div>

        {/* Progress Bar (only show for non-confirmation alerts) */}
        {!showConfirmButton && (
          <div className="absolute bottom-0 left-0 right-0 h-1">
            <div className={`h-full bg-gradient-to-r ${styles.border} animate-shrink-width`}></div>
          </div>
        )}
      </div>
    </div>
  );
};

const InfoCard = ({ onClose, action }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose?.();
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);

  return (
    <div className="bg-gray-900/80 backdrop-blur-xl rounded-2xl shadow-2xl overflow-hidden p-8 w-96 relative">
      {/* Animated Background */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-500/10 via-cyan-500/10 to-blue-500/10"></div>
      
      {/* Info Animation Container */}
      <div className="relative z-10 space-y-6">
        <div className="flex justify-center">
          <div className="relative w-24 h-24">
            {/* Outer info ring */}
            <div className="absolute inset-0 rounded-full border-4 border-blue-500/30"></div>
            
            {/* Pulsing info ring */}
            <div className="absolute inset-0 rounded-full border-4 border-blue-500/50 animate-pulse"></div>
            
            {/* Info icon container */}
            <div className="absolute inset-2 rounded-full bg-gradient-to-br from-blue-400 to-cyan-500 flex items-center justify-center">
              <Info className="w-12 h-12 text-white" />
            </div>
          </div>
        </div>

        {/* Info Message */}
        <div className="text-center space-y-3">
          <h2 className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-cyan-500 bg-clip-text text-transparent">
            Development Notice
          </h2>
          <p className="text-gray-300 text-sm">
            Please note: Apart from DSA, all other modules are currently in development and contain test data for demonstration purposes.
          </p>
        </div>
      </div>

      {/* Bottom border */}
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-blue-500 via-cyan-500 to-blue-500"></div>
    </div>
  );
};

const StatusDialog = ({ status, onSuccess, onClose, errorMessage, action = 'login' }) => {
  const [showLoading, setShowLoading] = useState(status === 'loading');
  const [currentStatus, setCurrentStatus] = useState(status);

  

  useEffect(() => {
    
    setCurrentStatus(status);
    if (status === 'loading') {
      setShowLoading(true);
    }
  }, [status]);

  const handleLoadingComplete = () => {
    
    setShowLoading(false);
    
    // If we're in success state, the SuccessCard will handle the transition
    if (currentStatus === 'success') {
      
    }
  };

  if (!status) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative">
        {showLoading && (
          <LoadingCard 
            onComplete={handleLoadingComplete} 
            action={action} 
          />
        )}
        {!showLoading && currentStatus === 'success' && (
          <SuccessCard 
            onSuccess={onSuccess} 
            action={action} 
          />
        )}
        {!showLoading && currentStatus === 'error' && (
          <ErrorCard 
            onClose={onClose} 
            errorMessage={errorMessage} 
          />
        )}
        {!showLoading && currentStatus === 'info' && (
          <InfoCard 
            onClose={onSuccess}
            action={action}
          />
        )}
      </div>
    </div>
  );
};

const PoAlertCard = ({ orders, action, onCancel, onProceed }) => {
  return (
    <AlertCard
      type="warning"
      title="Orders With Remarks"
      preventAutoClose={true}
      message={
        <div className="po-list-container">
          <p className="alert-description">
            The following orders have remarks. Do you want to proceed with {action}?
          </p>
          <div className="po-list custom-scrollbar">
            {orders.map((order, index) => (
              <div key={index} className="po-item">
                <div className="po-item-header">
                  <strong className="po-number">PO #{order.PoNumber}</strong>
                  <span className="po-badge">{order.Status}</span>
                </div>
                <div className="po-remarks">
                  <span className="remarks-label">Remarks:</span>
                  <p className="remarks-text">{order.Remarks}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="alert-actions">
            <button className="btn-cancel" onClick={onCancel}>
              Cancel
            </button>
            <button className="btn-proceed" onClick={onProceed}>
              Proceed Anyway
            </button>
          </div>
        </div>
      }
    />
  );
};

export default StatusDialog;
export { AlertCard, PoAlertCard };