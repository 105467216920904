import React, { useState, useCallback, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faFilter,
  faChartPie,
  faChartBar,
  faShoppingCart,
  faWeightHanging,
  faCheckCircle,
  faClock,
  faChevronDown,
  faArrowUp,
  faArrowDown,
  faTable
} from '@fortawesome/free-solid-svg-icons';
import './SummaryPage.css';
import * as XLSX from 'xlsx';

const calculateTrend = (currentValue, previousValue) => {
  if (previousValue === 0) return 0;
  return ((currentValue - previousValue) / previousValue * 100).toFixed(1);
};

const calculateMetrics = (data) => {
  const currentDate = new Date();
  const fifteenDaysAgo = new Date(currentDate);
  fifteenDaysAgo.setDate(currentDate.getDate() - 15);
  const thirtyDaysAgo = new Date(currentDate);
  thirtyDaysAgo.setDate(currentDate.getDate() - 30);

  // Current period (last 15 days)
  const currentPeriodData = data.filter(order => 
    new Date(order.order_date) >= fifteenDaysAgo
  );

  // Previous period (15-30 days ago)
  const previousPeriodData = data.filter(order => 
    new Date(order.order_date) >= thirtyDaysAgo && 
    new Date(order.order_date) < fifteenDaysAgo
  );

  const currentTotalOrders = currentPeriodData.length;
  const previousTotalOrders = previousPeriodData.length;
  const ordersTrend = calculateTrend(currentTotalOrders, previousTotalOrders);

  const currentWeightage = currentPeriodData.reduce((sum, order) => sum + order.volume, 0);
  const previousWeightage = previousPeriodData.reduce((sum, order) => sum + order.volume, 0);
  const weightageTrend = calculateTrend(currentWeightage, previousWeightage);

  const currentApproved = currentPeriodData.filter(order => order.status === 'Approved').length;
  const previousApproved = previousPeriodData.filter(order => order.status === 'Approved').length;
  const approvedTrend = calculateTrend(currentApproved, previousApproved);

  const currentPending = currentPeriodData.filter(order => order.status === 'Pending').length;
  const previousPending = previousPeriodData.filter(order => order.status === 'Pending').length;
  const pendingTrend = calculateTrend(currentPending, previousPending);

  return {
    totalOrders: {
      value: data.length,
      trend: parseFloat(ordersTrend)
    },
    totalWeightage: {
      value: parseFloat(data.reduce((sum, order) => sum + order.volume, 0).toFixed(2)),
      trend: parseFloat(weightageTrend)
    },
    approvedOrders: {
      value: data.filter(order => order.status === 'Approved').length,
      trend: parseFloat(approvedTrend)
    },
    pendingOrders: {
      value: data.filter(order => order.status === 'Pending').length,
      trend: parseFloat(pendingTrend)
    }
  };
};

const MetricCard = ({ title, value, trend, icon, sparklineData, color, type }) => {
  const [displayValue, setDisplayValue] = useState(0);
  
  useEffect(() => {
    // Reset to 0 when value changes
    setDisplayValue(0);
    
    const duration = 2000; // 2 seconds animation
    const steps = 20; // Reduced number of steps for smoother animation
    const stepValue = value / steps;
    let current = 0;
    
    const timer = setInterval(() => {
      current += stepValue;
      if (current >= value) {
        setDisplayValue(value);
        clearInterval(timer);
      } else {
        setDisplayValue(Math.floor(current));
      }
    }, duration / steps);
    
    // Ensure cleanup
    return () => {
      clearInterval(timer);
    };
  }, [value]); // Only re-run when value changes

  return (
    <div className="metric-card" data-type={type}>
      <div className="metric-header">
        <div className="metric-icon">
          <FontAwesomeIcon icon={icon} />
        </div>
        <div className={`metric-trend ${trend >= 0 ? 'positive' : 'negative'}`}>
          <FontAwesomeIcon icon={trend >= 0 ? faArrowUp : faArrowDown} />
          <span>{Math.abs(trend)}%</span>
        </div>
      </div>
      <div className="metric-content">
        <h3>{title}</h3>
        <p className="metric-value">{displayValue.toLocaleString()}</p>
        <div className="metric-sparkline">
          <SparklineChart data={sparklineData} color={color} />
        </div>
      </div>
    </div>
  );
};

const SparklineChart = ({ data, color, type = 'line' }) => {
  const options = {
    chart: {
      type: type,
      sparkline: {
        enabled: true
      }
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    colors: [color],
    tooltip: {
      fixed: {
        enabled: false
      },
      x: {
        show: false
      },
      marker: {
        show: false
      }
    }
  };

  return (
    <div className="sparkline-chart">
      <ReactApexChart
        options={options}
        series={[{ data: data }]}
        type={type}
        height={40}
      />
    </div>
  );
};

const generateDummyData = () => {
  const platforms = ['Flipkart', 'Amazon', 'Blinkit', 'Big Basket', 'Zepto'];
  const statuses = ['Approved', 'Pending'];
  const distributions = ['E-Commerce', 'Modern Trade'];
  
  return Array.from({ length: 100 }, (_, index) => {
    const orderDate = new Date();
    orderDate.setDate(orderDate.getDate() - Math.floor(Math.random() * 30));
    
    return {
      id: index + 1,
      order_number: `PO${Math.random().toString(36).substr(2, 8).toUpperCase()}`,
      platform: platforms[Math.floor(Math.random() * platforms.length)],
      Distribution: distributions[Math.floor(Math.random() * distributions.length)],
      status: statuses[Math.floor(Math.random() * statuses.length)],
      order_date: orderDate.toISOString(),
      total_sales_value: parseFloat((Math.random() * 10000).toFixed(2)),
      quantity: Math.floor(Math.random() * 100) + 1,
      volume: parseFloat((Math.random() * 1000).toFixed(2)),
      growth: parseFloat((Math.random() * 20 - 10).toFixed(1)) // -10 to +10
    };
  });
};

const SummaryPage = () => {
  const [chartOptions, setChartOptions] = useState({
    platformOptions: null,
    statusOptions: null,
    salesOptions: null
  });
  const [dashboardData, setDashboardData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [filters, setFilters] = useState({
    Distribution: 'All',
    platform: 'All',
    fromDate: '',
    toDate: '',
    status: 'All'
  });
  const [theme] = useState('light');
  const [collapsedSections, setCollapsedSections] = useState({
    filters: false,
    metrics: false,
    charts: false,
    table: false
  });
  const [metrics, setMetrics] = useState({
    totalOrders: { value: 0, trend: 0 },
    totalWeightage: { value: 0, trend: 0 },
    approvedOrders: { value: 0, trend: 0 },
    pendingOrders: { value: 0, trend: 0 }
  });

  const applyFilters = useCallback((data) => {
    return data.filter(order => {
      const orderDate = new Date(order.order_date).setHours(0, 0, 0, 0);
      const fromDate = filters.fromDate ? new Date(filters.fromDate).setHours(0, 0, 0, 0) : null;
      const toDate = filters.toDate ? new Date(filters.toDate).setHours(23, 59, 59, 999) : null;

      return (
        (filters.Distribution === 'All' || order.Distribution === filters.Distribution) &&
        (filters.platform === 'All' || order.platform === filters.platform) &&
        (filters.status === 'All' || order.status === filters.status) &&
        (!fromDate || orderDate >= fromDate) &&
        (!toDate || orderDate <= toDate)
      );
    });
  }, [filters]);

  const calculatePlatformData = useCallback((data) => {
    const platformCounts = data.reduce((acc, order) => {
      acc[order.platform] = (acc[order.platform] || 0) + 1;
      return acc;
    }, {});

    return {
      labels: Object.keys(platformCounts),
      series: Object.values(platformCounts)
    };
  }, []);

  const calculateStatusData = useCallback((data) => {
    const statusCounts = data.reduce((acc, order) => {
      acc[order.status] = (acc[order.status] || 0) + 1;
      return acc;
    }, {});

    return {
      labels: Object.keys(statusCounts),
      series: Object.values(statusCounts)
    };
  }, []);

  const calculateSalesData = useCallback((data) => {
    const salesByPlatform = data.reduce((acc, order) => {
      if (!acc[order.platform]) {
        acc[order.platform] = {};
      }
      const date = new Date(order.order_date).toISOString().split('T')[0];
      acc[order.platform][date] = (acc[order.platform][date] || 0) + order.total_sales_value;
      return acc;
    }, {});

    const categories = [...new Set(data.map(order => 
      new Date(order.order_date).toISOString().split('T')[0]))].sort();

    const series = Object.keys(salesByPlatform).map(platform => ({
      name: platform,
      data: categories.map(date => salesByPlatform[platform][date] || 0)
    }));

    return { categories, series };
  }, []);

  const calculateSummaryData = useCallback((data) => {
    const summary = data.reduce((acc, order) => {
      const platform = order.platform;
      if (!acc[platform]) {
        acc[platform] = {
          platform,
          totalOrders: 0,
          totalValue: 0,
          totalQuantity: 0,
          totalVolume: 0,
          approvedOrders: 0,
          pendingOrders: 0
        };
      }
      acc[platform].totalOrders += 1;
      acc[platform].totalValue += parseFloat(order.total_sales_value) || 0;
      acc[platform].totalQuantity += parseInt(order.quantity) || 0;
      acc[platform].totalVolume += parseFloat(order.volume) || 0;
      if (order.status === 'Approved') {
        acc[platform].approvedOrders += 1;
      } else if (order.status === 'Pending') {
        acc[platform].pendingOrders += 1;
      }
      return acc;
    }, {});

    setSummaryData(Object.values(summary));
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const dummyData = generateDummyData();
      const filteredData = applyFilters(dummyData);
      setDashboardData(filteredData);
      setMetrics(calculateMetrics(filteredData));
      
      const platformData = calculatePlatformData(filteredData);
      const statusData = calculateStatusData(filteredData);
      const salesData = calculateSalesData(filteredData);

      const commonResponsiveOptions = [{
        breakpoint: 480,
        options: {
          chart: { height: 300 },
          legend: {
            position: 'bottom',
            offsetY: 0,
            height: 50
          },
          plotOptions: {
            pie: { donut: { size: '65%' } }
          }
        }
      }];

      setChartOptions({
        platformOptions: {
          series: platformData.series,
          chart: { type: 'donut', height: 350 },
          labels: platformData.labels,
          colors: ['#3498db', '#e67e22', '#9b59b6', '#1abc9c', '#34495e'],
          title: {
            text: 'Orders by Platform',
            align: 'center'
          },
          legend: {
            position: 'bottom'
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    label: 'Total',
                    formatter: function (w) {
                      return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                    }
                  }
                }
              }
            }
          },
          responsive: commonResponsiveOptions
        },
        statusOptions: {
          series: statusData.series,
          chart: { type: 'donut', height: 350 },
          labels: statusData.labels,
          colors: ['#2ecc71', '#f39c12'],
          title: {
            text: 'Order Status Distribution',
            align: 'center'
          },
          legend: {
            position: 'bottom'
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    label: 'Total',
                    formatter: function (w) {
                      return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                    }
                  }
                }
              }
            }
          },
          responsive: commonResponsiveOptions
        },
        salesOptions: {
          series: salesData.series,
          chart: { height: 350, type: 'line' },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            type: 'datetime',
            categories: salesData.categories
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy'
            },
          },
          title: {
            text: 'Orders by Date and Platform',
            align: 'center'
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                height: 300
              },
              legend: {
                position: 'bottom'
              },
              xaxis: {
                labels: {
                  rotate: -45,
                  maxHeight: 60
                }
              }
            }
          }]
        }
      });
      
      calculateSummaryData(filteredData);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  }, [applyFilters, calculatePlatformData, calculateStatusData, calculateSalesData, calculateSummaryData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const toggleSection = (section) => {
    setCollapsedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    
    // Summary data
    const summarySheet = XLSX.utils.json_to_sheet(summaryData);
    XLSX.utils.book_append_sheet(workbook, summarySheet, "Summary");
    
    // Raw data
    const rawDataSheet = XLSX.utils.json_to_sheet(dashboardData);
    XLSX.utils.book_append_sheet(workbook, rawDataSheet, "Raw Data");
    
    // Save the file
    XLSX.writeFile(workbook, `Dashboard_Export_${new Date().toISOString().split('T')[0]}.xlsx`);
  };

  const TableHeader = () => (
    <div className="section-title">
      <FontAwesomeIcon icon={faTable} />
      <h2>Summary Table</h2>
      <button 
        className="export-button" 
        onClick={exportToExcel}
        aria-label="Export to Excel"
      >
        Export to Excel
      </button>
    </div>
  );

  return (
    <div className={`dashboard-content ${theme}`}>
      <div className="summary-scroll-container">
        <div className={`filters-container collapsible ${collapsedSections.filters ? 'collapsed' : ''}`}>
          <div className="section-title" onClick={() => toggleSection('filters')}>
            <FontAwesomeIcon icon={faFilter} />
            <h2>Filters</h2>
            <FontAwesomeIcon 
              icon={faChevronDown} 
              className={`toggle-icon ${collapsedSections.filters ? 'rotated' : ''}`}
            />
          </div>
          <div className="section-content">
            <div className="filters" role="search" aria-label="Filter orders">
              <div className="filter">
                <label htmlFor="distribution">Distribution</label>
                <select 
                  id="distribution"
                  value={filters.Distribution}
                  onChange={(e) => handleFilterChange('Distribution', e.target.value)}
                >
                  <option value="All">All</option>
                  <option value="E-Commerce">E-Commerce</option>
                  <option value="Modern Trade">Modern Trade</option>
                </select>
              </div>
              <div className="filter">
                <label>Platform</label>
                <select onChange={(e) => handleFilterChange('platform', e.target.value)}>
                  <option value="All">All</option>
                  <option value="Flipkart">Flipkart</option>
                  <option value="Amazon">Amazon</option>
                  <option value="Blinkit">Blinkit</option>
                  <option value="Big Basket">Big Basket</option>
                  <option value="Zepto">Zepto</option>
                </select>
              </div>
              <div className="filter">
                <label>From Date</label>
                <input type="date" onChange={(e) => handleFilterChange('fromDate', e.target.value)} />
              </div>
              <div className="filter">
                <label>To Date</label>
                <input type="date" onChange={(e) => handleFilterChange('toDate', e.target.value)} />
              </div>
              <div className="filter">
                <label>Status</label>
                <select onChange={(e) => handleFilterChange('status', e.target.value)}>
                  <option value="All">All</option>
                  <option value="Approved">Approved</option>
                  <option value="Pending">Pending</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="metrics-container">
          <div className="section-title">
            <FontAwesomeIcon icon={faChartLine} />
            <h2>Key Metrics</h2>
          </div>
          <div className="metrics" role="region" aria-label="Key metrics">
            <MetricCard
              title="Total Orders"
              value={metrics.totalOrders.value}
              trend={metrics.totalOrders.trend}
              icon={faShoppingCart}
              sparklineData={[30, 40, 35, 50, 49, 60, 70, 91, 125]}
              color="var(--accent-color)"
              type="orders"
            />
            <MetricCard
              title="Total Weightage"
              value={metrics.totalWeightage.value}
              trend={metrics.totalWeightage.trend}
              icon={faWeightHanging}
              sparklineData={[45, 43, 48, 46, 50, 48, 52, 53, 56]}
              color="var(--success-color)"
              type="weightage"
            />
            <MetricCard
              title="Approved Orders"
              value={metrics.approvedOrders.value}
              trend={metrics.approvedOrders.trend}
              icon={faCheckCircle}
              sparklineData={[25, 30, 35, 38, 40, 45, 48, 50, 55]}
              color="var(--warning-color)"
              type="approved"
            />
            <MetricCard
              title="Pending Orders"
              value={metrics.pendingOrders.value}
              trend={metrics.pendingOrders.trend}
              icon={faClock}
              sparklineData={[20, 18, 15, 14, 13, 12, 11, 10, 9]}
              color="var(--danger-color)"
              type="pending"
            />
          </div>
        </div>

        <div className="charts-container">
          <div className="section-title">
            <FontAwesomeIcon icon={faChartPie} />
            <h2>Analytics</h2>
            <div className="chart-actions">
              <button className="chart-type-btn">
                <FontAwesomeIcon icon={faChartBar} />
              </button>
            </div>
          </div>
          <div className="chart-container" role="region" aria-label="Order analytics">
            <div className="chart-card">
              {chartOptions.platformOptions && (
                <ReactApexChart 
                  options={{
                    ...chartOptions.platformOptions,
                    chart: {
                      ...chartOptions.platformOptions.chart,
                      animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                      },
                      dropShadow: {
                        enabled: true,
                        blur: 3,
                        opacity: 0.2
                      }
                    }
                  }}
                  series={chartOptions.platformOptions.series}
                  type="donut"
                  height={window.innerWidth <= 768 ? 300 : 350}
                />
              )}
            </div>
            <div className="chart-card">
              {chartOptions.statusOptions && (
                <ReactApexChart 
                  options={{
                    ...chartOptions.statusOptions,
                    chart: {
                      ...chartOptions.statusOptions.chart,
                      animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                      },
                      dropShadow: {
                        enabled: true,
                        blur: 3,
                        opacity: 0.2
                      }
                    }
                  }}
                  series={chartOptions.statusOptions.series}
                  type="donut"
                  height={window.innerWidth <= 768 ? 300 : 350}
                />
              )}
            </div>
          </div>
        </div>

        <div className="table-container">
          <TableHeader />
          <div className="table-card">
            <div className="table-scroll">
              <table>
                <thead>
                  <tr>
                    <th>Platform</th>
                    <th>Total Orders</th>
                    <th>Total Value</th>
                    <th>Total Quantity</th>
                    <th>Total Volume</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {summaryData.map((platform) => (
                    <tr key={platform.platform}>
                      <td>{platform.platform}</td>
                      <td>{platform.totalOrders}</td>
                      <td>₹{(parseFloat(platform.totalValue) || 0).toFixed(2)}</td>
                      <td>{platform.totalQuantity}</td>
                      <td>{(parseFloat(platform.totalVolume) || 0).toFixed(2)} kg</td>
                      <td>
                        <div className="status-container">
                          <span className="status-pill status-approved">{platform.approvedOrders} Approved</span>
                          <span className="status-pill status-pending">{platform.pendingOrders} Pending</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="chart-area chart-responsive">
          {chartOptions.salesOptions && (
            <ReactApexChart 
              options={chartOptions.salesOptions} 
              series={chartOptions.salesOptions.series} 
              type="line"
              height={window.innerWidth <= 768 ? 300 : 350}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SummaryPage;