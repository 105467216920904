import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/login';
import Dashboard from './components/Dashboard';
import { AuthProvider, useAuth } from './context/AuthContext';
import UnderConstruction from './components/UnderConstruction';
import AuthorizationDenied from './components/Authorizationdenied';
import AdminDashboard from './components/Admin/AdminDashboard';
import Masters from './components/PO Dashboard/Masters';
import Summary from './components/PO Dashboard/SummaryPage';
import Potable from './components/PO Dashboard/PoTable';
import ExecutiveSummary from './components/Ecom/ExecutiveSummary';
import SalesAnalytics from './components/Ecom/SalesAnalytics';
import FullScreenPowerBIEmbed from './components/PowerBI/powerbi';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return <div className="loading-container">Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/AuthorizationDenied" element={<AuthorizationDenied />} />
          
          {/* Protected Routes */}
          <Route path="/" element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }>
            {/* DSA Routes */}
            <Route path="dsa" element={<FullScreenPowerBIEmbed reportType="dsa" />} />
            <Route path="dsa/:pageId" element={<FullScreenPowerBIEmbed reportType="dsa" />} />
            
            {/* Ecom Dashboard Routes */}
            <Route path="ecom/executive-summary" element={<ExecutiveSummary />} />
            <Route path="ecom/sales" element={<SalesAnalytics />} />
            <Route path="ecom/revenue" element={<UnderConstruction />} />
            <Route path="ecom/products" element={<UnderConstruction />} />
            <Route path="ecom/marketing" element={<UnderConstruction />} />
            
            {/* PO Dashboard Routes */}
            <Route path="po/summary" element={<Summary />} />
            <Route path="po/table" element={<Potable />} />
            <Route path="po/master" element={<Masters />} />
            
            {/* Admin Route */}
            <Route path="admin" element={<AdminDashboard />} />
            
            {/* Default Route */}
            <Route index element={<Navigate to="dsa" replace />} />
          </Route>

          {/* Catch-all redirect to main dashboard */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
