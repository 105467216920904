import React, { useState, useEffect } from 'react';
import { 
  Bell, 
  Settings, 
  X, 
  Trash2,
  Clock,
  CheckCircle,
  AlertTriangle,
  Info,
  ChevronDown
} from 'lucide-react';
import './NotificationBar.css';

const NotificationBar = ({ 
  notifications, 
  showNotifications,
  toggleNotifications,
  clearAllNotifications,
  removeNotification,
  notificationRef,
  setIsSettingsOpen,
  user
}) => {
  const [lastActive, setLastActive] = useState(new Date());
  const [userStatus, setUserStatus] = useState('online');
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  // Enhanced avatar generation with initials
  const getInitials = (name) => {
    if (!name) return 'U';
    return name.split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  // Avatar component with fallback
  const UserAvatar = ({ user, className }) => {
    const [imageError, setImageError] = useState(false);
    
    if (!user?.profile_picture || imageError) {
      return (
        <div className={`${className} avatar-fallback`}>
          {getInitials(user?.name || 'User')}
        </div>
      );
    }

    return (
      <img
        src={user.profile_picture}
        alt={user?.name || 'User'}
        className={className}
        onError={(e) => {
          setImageError(true);
        }}
      />
    );
  };

  const getNotificationIcon = (type) => {
    switch (type) {
      case 'success':
        return <CheckCircle size={14} className="notification-icon success" />;
      case 'warning':
        return <AlertTriangle size={14} className="notification-icon warning" />;
      case 'info':
      default:
        return <Info size={14} className="notification-icon info" />;
    }
  };

  // Status monitoring logic...
  useEffect(() => {
    const updateLastActive = () => setLastActive(new Date());
    const events = ['mousemove', 'keydown', 'click', 'scroll'];
    
    events.forEach(event => window.addEventListener(event, updateLastActive));
    
    const statusInterval = setInterval(() => {
      const minutesSinceLastActive = (new Date() - lastActive) / (1000 * 60);
      setUserStatus(
        minutesSinceLastActive > 5 ? 'offline' : 
        minutesSinceLastActive > 3 ? 'away' : 'online'
      );
    }, 30000);

    return () => {
      events.forEach(event => window.removeEventListener(event, updateLastActive));
      clearInterval(statusInterval);
    };
  }, [lastActive]);

  return (
    <div className="header-right">
      <div className="notification-wrapper">
        <button 
          className="icon-button notification-btn"
          onClick={() => toggleNotifications('all')}
        >
          <Bell size={20} strokeWidth={1.5} />
          {notifications.length > 0 && (
            <span className="notification-badge">{notifications.length}</span>
          )}
        </button>

        {showNotifications && (
          <div className="notification-dropdown" ref={notificationRef}>
            <div className="notification-header">
              <h3>Notifications</h3>
              <button className="clear-all-btn" onClick={() => clearAllNotifications('all')}>
                <Trash2 size={14} /> Clear all
              </button>
            </div>
            <div className="notification-list">
              {notifications.length === 0 ? (
                <div className="no-notifications">No new notifications</div>
              ) : (
                notifications.map(notification => (
                  <div key={notification.id} className="notification-item">
                    <div className="notification-content">
                      {getNotificationIcon(notification.type)}
                      <div className="notification-text">
                        <span className="notification-message">{notification.message}</span>
                        <span className="notification-time">
                          <Clock size={12} /> {notification.time}
                        </span>
                      </div>
                    </div>
                    <button 
                      className="remove-notification"
                      onClick={() => removeNotification('all', notification.id)}
                    >
                      <X size={14} />
                    </button>
                  </div>
                ))
              )}
            </div>
          </div>
        )}
      </div>

      <button 
        className="icon-button settings-btn"
        onClick={() => setIsSettingsOpen(true)}
      >
        <Settings size={20} strokeWidth={1.5} />
      </button>

      <div className="profile-dropdown-wrapper">
        <button 
          className="profile-trigger"
          onClick={() => setShowProfileMenu(!showProfileMenu)}
        >
          <div className="avatar-container">
            <UserAvatar user={user} className="user-avatar" />
            <span className={`status-dot ${userStatus}`}></span>
          </div>
          <div className="user-info">
            <span className="user-name">{user?.name || 'User'}</span>
            <span className="user-role">{user?.role || 'Team Member'}</span>
          </div>
          <ChevronDown 
            size={16} 
            className={`profile-chevron ${showProfileMenu ? 'rotated' : ''}`}
          />
        </button>

        {showProfileMenu && (
          <div className="profile-dropdown">
            <div className="profile-header">
              <UserAvatar user={user} className="profile-picture" />
              <div>
                <h4>{user?.name || 'User'}</h4>
                <p>{user?.email || 'email@example.com'}</p>
              </div>
            </div>
            <div className="profile-menu">
              <button onClick={() => setIsSettingsOpen(true)}>
                <Settings size={16} />
                Settings
              </button>
              {/* Add more menu items as needed */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationBar; 