import React, { useState, useEffect } from 'react';
import { 
  Table, Button, Modal, Form, Input, Select, Avatar, Checkbox,
  Space, notification, Card, Divider, Tag, Tooltip, Tabs,
  Switch, Collapse, Alert, Row, Col
} from 'antd';
import moment from 'moment';
import { 
  PlusOutlined, EditOutlined, DeleteOutlined, UserOutlined, 
  ExclamationCircleOutlined, SearchOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import './AdminDashboard.css';

const { Option } = Select;
const { confirm } = Modal;

const platforms = ['Amazon', 'Flipkart', 'Blinkit', 'Meesho', 'Myntra'];
const roles = ['Admin', 'Manager', 'User', 'Analyst', 'Support'];
const departments = ['IT', 'HR', 'Finance', 'Marketing', 'Operations'];

const permissionGroups = {
  basic: {
    title: 'Basic Access',
    permissions: ['view', 'edit', 'approve']
  },
  data: {
    title: 'Data Access',
    permissions: ['reports', 'analytics', 'dashboard']
  },
  platform: {
    title: 'Platform Access',
    permissions: ['orders', 'returns', 'inventory']
  }
};

const QuickFilters = ({ onFilterChange, activeFilter }) => (
  <div className="quick-filters">
    <div 
      className={`filter-chip ${activeFilter === 'all' ? 'active' : ''}`}
      onClick={() => onFilterChange('all')}
    >
      All Users
    </div>
    <div 
      className={`filter-chip ${activeFilter === 'active' ? 'active' : ''}`}
      onClick={() => onFilterChange('active')}
    >
      Active
    </div>
    <div 
      className={`filter-chip ${activeFilter === 'inactive' ? 'active' : ''}`}
      onClick={() => onFilterChange('inactive')}
    >
      Inactive
    </div>
    <div 
      className={`filter-chip ${activeFilter === 'admins' ? 'active' : ''}`}
      onClick={() => onFilterChange('admins')}
    >
      Admins
    </div>
    <div 
      className={`filter-chip ${activeFilter === 'managers' ? 'active' : ''}`}
      onClick={() => onFilterChange('managers')}
    >
      Managers
    </div>
  </div>
);

const SearchBar = ({ onSearch, value }) => (
  <div className="search-container">
    <SearchOutlined className="search-icon" />
    <input 
      className="search-input"
      placeholder="Search users..."
      value={value}
      onChange={(e) => onSearch(e.target.value)}
    />
  </div>
);

const AdminDashboard = () => {
  // State Management
  const [users, setUsers] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingUser, setEditingUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [activeFilter, setActiveFilter] = useState('all');

  // Fetch Data
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      // Mock API call with more users
      const mockUsers = [
        {
          id: 1,
          name: 'John Doe',
          email: 'john@example.com',
          role: 'Admin',
          department: 'IT',
          status: true,
          lastLogin: '2023-08-15',
          platformPermissions: {
            Amazon: { canView: true, canEdit: true, canApprove: true, dateRestriction: null },
            Flipkart: { canView: true, canEdit: false, canApprove: false, dateRestriction: '30' },
            Blinkit: { canView: true, canEdit: false, canApprove: false, dateRestriction: null }
          }
        },
        {
          id: 2,
          name: 'Jane Smith',
          email: 'jane@example.com',
          role: 'Manager',
          department: 'Operations',
          status: true,
          lastLogin: '2023-08-16',
          platformPermissions: {
            Amazon: { canView: true, canEdit: true, canApprove: false, dateRestriction: '90' },
            Meesho: { canView: true, canEdit: true, canApprove: true, dateRestriction: null }
          }
        },
        {
          id: 3,
          name: 'Mike Johnson',
          email: 'mike@example.com',
          role: 'Analyst',
          department: 'Finance',
          status: false,
          lastLogin: '2023-08-14',
          platformPermissions: {
            Flipkart: { canView: true, canEdit: false, canApprove: false, dateRestriction: '30' },
            Myntra: { canView: true, canEdit: false, canApprove: false, dateRestriction: '30' }
          }
        },
        {
          id: 4,
          name: 'Sarah Wilson',
          email: 'sarah@example.com',
          role: 'Support',
          department: 'HR',
          status: true,
          lastLogin: '2023-08-16',
          platformPermissions: {
            Blinkit: { canView: true, canEdit: true, canApprove: false, dateRestriction: null }
          }
        },
        {
          id: 5,
          name: 'Alex Brown',
          email: 'alex@example.com',
          role: 'User',
          department: 'Marketing',
          status: true,
          lastLogin: '2023-08-15',
          platformPermissions: {
            Amazon: { canView: true, canEdit: false, canApprove: false, dateRestriction: '90' },
            Flipkart: { canView: true, canEdit: false, canApprove: false, dateRestriction: '90' }
          }
        }
      ];
      setUsers(mockUsers);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to fetch users'
      });
    } finally {
      setLoading(false);
    }
  };

  // CRUD Operations
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (editingUser) {
        const updatedUsers = users.map(user => 
          user.id === editingUser.id ? { ...user, ...values } : user
        );
        setUsers(updatedUsers);
        notification.success({
          message: 'Success',
          description: 'User updated successfully'
        });
      } else {
        const newUser = {
          id: users.length + 1,
          ...values,
          status: true,
          lastLogin: moment().format('YYYY-MM-DD')
        };
        setUsers([...users, newUser]);
        notification.success({
          message: 'Success',
          description: 'User created successfully'
        });
      }
      handleModalClose();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Operation failed'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (userId) => {
    confirm({
      title: 'Are you sure you want to delete this user?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        setUsers(users.filter(user => user.id !== userId));
        notification.success({
          message: 'Success',
          description: 'User deleted successfully'
        });
      }
    });
  };

  // UI Handlers
  const handleModalOpen = (user = null) => {
    setEditingUser(user);
    if (user) {
      form.setFieldsValue(user);
    } else {
      form.resetFields();
    }
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setEditingUser(null);
    form.resetFields();
  };

  // Table Configuration
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Space>
          <Avatar 
            icon={<UserOutlined />} 
            style={{ 
              backgroundColor: record.status ? '#4f46e5' : '#94a3b8',
              verticalAlign: 'middle' 
            }} 
          />
          <span>{text}</span>
        </Space>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (role) => (
        <span className={`role-tag ${role.toLowerCase()}`}>
          {role}
        </span>
      )
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: 'Platforms',
      key: 'platformPermissions',
      render: (_, record) => (
        <div className="platform-tags">
          {Object.keys(record.platformPermissions || {}).map(platform => (
            record.platformPermissions[platform].canView && (
              <span key={platform} className="platform-tag">
                {platform}
              </span>
            )
          ))}
        </div>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span className={`status-pill ${status ? 'status-active' : 'status-inactive'}`}>
          {status ? 'Active' : 'Inactive'}
        </span>
      )
    },
    {
      title: 'Platform Access',
      key: 'platformPermissions',
      render: (_, record) => (
        <div className="platform-access">
          {Object.entries(record.platformPermissions || {}).map(([platform, perms]) => (
            perms.canView && (
              <Tooltip key={platform} title={
                <div>
                  {perms.canEdit && '✓ Can Edit'}<br/>
                  {perms.canApprove && '✓ Can Approve'}<br/>
                  {perms.dateRestriction && `History: ${perms.dateRestriction} days`}
                </div>
              }>
                <Tag color={perms.canApprove ? 'green' : perms.canEdit ? 'blue' : 'default'}>
                  {platform}
                </Tag>
              </Tooltip>
            )
          ))}
        </div>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button 
            type="primary" 
            icon={<EditOutlined />}
            onClick={() => handleModalOpen(record)}
            className="edit-btn"
          >
            Edit
          </Button>
          <Button 
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.id)}
            className="delete-btn"
          >
            Delete
          </Button>
        </Space>
      )
    }
  ];

  const handleSearch = (value) => {
    setSearchText(value);
    // Filter users based on search text
    const filtered = users.filter(user => 
      user.name.toLowerCase().includes(value.toLowerCase()) ||
      user.email.toLowerCase().includes(value.toLowerCase()) ||
      user.role.toLowerCase().includes(value.toLowerCase()) ||
      user.department.toLowerCase().includes(value.toLowerCase())
    );
    setUsers(filtered);
  };

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
    // Reset search
    setSearchText('');
    
    // Filter users based on selected filter
    let filtered = [...users];
    switch(filter) {
      case 'active':
        filtered = users.filter(user => user.status === true);
        break;
      case 'inactive':
        filtered = users.filter(user => user.status === false);
        break;
      case 'admins':
        filtered = users.filter(user => user.role === 'Admin');
        break;
      case 'managers':
        filtered = users.filter(user => user.role === 'Manager');
        break;
      default:
        // 'all' - no filtering needed
        break;
    }
    setUsers(filtered);
  };

  return (
    <div className="admin-dashboard">
      <div className="dashboard-container">
        <div className="dashboard-header">
          <div className="header-left">
            <h2 className="dashboard-title">
              <UserOutlined />
              User Management
            </h2>
            <SearchBar 
              onSearch={handleSearch} 
              value={searchText}
            />
          </div>
          <div className="header-actions">
            <Button 
              className="header-btn primary"
              icon={<PlusOutlined />} 
              onClick={() => handleModalOpen()}
            >
              Add User
            </Button>
            <Button 
              className="header-btn secondary"
              icon={<ReloadOutlined />} 
              onClick={fetchUsers}
            >
              Refresh
            </Button>
          </div>
        </div>

        <QuickFilters 
          onFilterChange={handleFilterChange}
          activeFilter={activeFilter}
        />
        
        <div className="table-container">
          <Table 
            columns={columns}
            dataSource={users}
            rowKey="id"
            loading={loading}
            pagination={false}
            scroll={{ y: 'calc(100vh - 280px)' }}
            className="user-table"
          />
        </div>
      </div>

      {/* User Form Modal */}
      <Modal
        visible={modalVisible}
        title={
          <div className="modal-header">
            {editingUser ? <EditOutlined /> : <PlusOutlined />}
            <span>{editingUser ? "Edit User" : "Add New User"}</span>
          </div>
        }
        onCancel={handleModalClose}
        footer={null}
        width={800}
        className="user-modal"
      >
        <UserForm 
          form={form}
          onFinish={handleSubmit}
          editingUser={editingUser}
          loading={loading}
        />
      </Modal>
    </div>
  );
};

const UserForm = ({ form, onFinish, editingUser, loading }) => {
  const [activeTab, setActiveTab] = useState('basic');
  
  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <Tabs.TabPane tab="Basic Information" key="basic">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="role" label="Role" rules={[{ required: true }]}>
                <Select>
                  {roles.map(role => (
                    <Option key={role} value={role}>{role}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="department" label="Department" rules={[{ required: true }]}>
                <Select>
                  {departments.map(dept => (
                    <Option key={dept} value={dept}>{dept}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Tabs.TabPane>
        
        <Tabs.TabPane tab="Permissions" key="permissions">
          <Alert
            message="Platform Access Configuration"
            description="Configure user access levels and restrictions for each platform."
            type="info"
            showIcon
            style={{ marginBottom: 16 }}
          />
          
          <div className="platform-permissions-grid">
            {platforms.map(platform => (
              <PermissionGroupForm
                key={platform}
                platform={platform}
                form={form}
                initialValues={editingUser?.platformPermissions?.[platform]}
              />
            ))}
          </div>
        </Tabs.TabPane>
      </Tabs>
      
      <Divider />
      
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} block>
          {editingUser ? 'Update User' : 'Create User'}
        </Button>
      </Form.Item>
    </Form>
  );
};

const PermissionGroupForm = ({ platform, form, initialValues }) => {
  const [expandedSections, setExpandedSections] = useState(['basic']);
  
  return (
    <Card 
      title={platform}
      className="permission-card"
      extra={
        <Switch
          checkedChildren="Enabled"
          unCheckedChildren="Disabled"
          checked={form.getFieldValue(['platformPermissions', platform, 'enabled'])}
          onChange={(checked) => {
            form.setFieldsValue({
              platformPermissions: {
                [platform]: { enabled: checked }
              }
            });
          }}
        />
      }
    >
      <Collapse 
        activeKey={expandedSections}
        onChange={setExpandedSections}
        className="permission-collapse"
      >
        {Object.entries(permissionGroups).map(([key, group]) => (
          <Collapse.Panel 
            key={key}
            header={group.title}
            className="permission-section"
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              {group.permissions.map(permission => (
                <Form.Item
                  key={permission}
                  name={['platformPermissions', platform, key, permission]}
                  valuePropName="checked"
                >
                  <Checkbox>
                    {permission.charAt(0).toUpperCase() + permission.slice(1)}
                  </Checkbox>
                </Form.Item>
              ))}
            </Space>
          </Collapse.Panel>
        ))}
      </Collapse>
    </Card>
  );
};

export default AdminDashboard;