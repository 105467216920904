// services/apiService.js
import axiosInstance from './axiosConfig';

const API_BASE_URL = process.env.REACT_APP_API_URL;

// Function to get the token from local storage
const getAuthToken = () => {
  return localStorage.getItem('token');
};

// Add Power BI config fetch function
export const fetchPowerBIConfig = async () => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.token;

    const response = await axiosInstance.get(
      `${API_BASE_URL}/powerbi/report/${user.client_id}/${user.product_id}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    
    return response.data;
  } catch (error) {
    console.error('Error fetching Power BI config:', error);
    throw error;
  }
};

// Add Power BI report by page fetch function
export const fetchPowerBIReportByPage = async (pageId) => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.token;

    // Ensure pageId is properly encoded
    const encodedPageId = encodeURIComponent(pageId);
    
    const response = await axiosInstance.get(
      `${API_BASE_URL}/powerbi/report/${user.client_id}/${user.product_id}/${encodedPageId}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching Power BI report by page:', error);
    throw error;
  }
};

// Existing functions
export const fetchDataFromAPI = async (platform) => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/app/data?platform=${platform}`, {
    
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data from API:', error);
    throw error;
  }
};



export const fetchOrderDetails = async () => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/app/data`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching order details:', error);
    throw error;
  }
};

export const fetchDashboardData = async () => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/app/orders`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error;
  }
};

export const fetchPodata = async (Ponumber) => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/app/orders/${Ponumber}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching order summaries:', error);
    throw error;
  }
};

// Function to approve orders
export const approveOrders = async (orderData) => {
  try {
    const token = getAuthToken();
    
    // Validate order data before sending
    if (!orderData.orders || !Array.isArray(orderData.orders) || orderData.orders.length === 0) {
      throw new Error('Invalid orders data');
    }
   
    const response = await axiosInstance.post(
      `${API_BASE_URL}/app/orders/approve`,
      {
        orders: orderData.orders,
        userId: orderData.userId,
        userName: orderData.userName,
        platform: orderData.platform
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error approving orders:', error);
    throw error;
  }
};



export const fetchLogsByPO = async (poNumber) => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/logs/${poNumber}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching logs:', error);
    throw error;
  }
};

export const editOrderItem = async (poNumber, poItemId, data) => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.put(
      `${API_BASE_URL}/app/orders/${poNumber}/items/${poItemId}`,
      data,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error editing order item:', error);
    throw error;
  }
};

export const fetchMasters = async (platform) => {
  try {
    
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/app/masters/${platform}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching masters:', error);
    throw error;
  }
};

export const fetchPlatforms = async () => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/app/platforms`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching platforms:', error);
    throw error;
  }
};
