import React, { useState, useEffect, useRef } from 'react';
import ReactApexChart from 'react-apexcharts';
import { 
  TrendingUp, DollarSign, Activity,
  ArrowUp, ArrowDown, ShoppingBag, Target,
  Users, Globe, Award, Map, BarChart2,
  PieChart, Package, ShoppingCart,
  Star, Truck
} from 'lucide-react';
import './ExecutiveSummary.css';

const animateValue = (obj, start, end, duration) => {
  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    obj.innerHTML = Math.floor(progress * (end - start) + start).toLocaleString();
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
};

// First, define the base lineChartOptions
const baseLineChartOptions = {
  chart: {
    type: 'line',
    height: 350,
    toolbar: {
      show: false
    },
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 1000,
      animateGradually: {
        enabled: true,
        delay: 150
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350
      }
    },
    dropShadow: {
      enabled: true,
      color: '#000',
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2
    }
  },
  stroke: {
    curve: 'smooth',
    width: 3,
    lineCap: 'round'
  },
  colors: ['#4f46e5', '#3b82f6', '#06b6d4'],
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
    labels: {
      style: {
        fontSize: '12px',
        fontFamily: 'Inter, sans-serif'
      }
    }
  },
  yaxis: {
    title: {
      text: 'Performance (%)',
      style: {
        fontSize: '14px',
        fontWeight: 500
      }
    },
    labels: {
      formatter: function (val) {
        return val.toFixed(0) + '%';
      }
    }
  },
  markers: {
    size: 6,
    colors: ['#fff'],
    strokeColors: ['#4f46e5', '#3b82f6', '#06b6d4'],
    strokeWidth: 3,
    hover: {
      size: 8,
      sizeOffset: 3
    }
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      type: 'vertical',
      shadeIntensity: 0.5,
      gradientToColors: undefined,
      inverseColors: true,
      opacityFrom: 0.8,
      opacityTo: 0.2,
      stops: [0, 100]
    }
  },
  grid: {
    borderColor: '#f1f1f1',
    strokeDashArray: 4,
    xaxis: {
      lines: {
        show: true
      }
    }
  },
  tooltip: {
    theme: 'light',
    y: {
      formatter: function(val) {
        return val.toFixed(1) + '%';
      }
    }
  },
  legend: {
    position: 'top',
    horizontalAlign: 'right',
    floating: true,
    offsetY: -25,
    offsetX: -5
  }
};

const ExecutiveSummary = () => {
  const [kpiData] = useState({
    totalShipments: 2458960,
    marketplaceGrowth: 15.8,
    purchaseOrders: 18645,
    avgOrderSize: 285,
    marketplaceShare: 3.2,
    brandPerformance: 224
  });

  const [salesData] = useState({
    series: [{
      name: 'Revenue',
      data: [28000, 29000, 33000, 36000, 32000, 32000, 33000]
    }],
    categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan']
  });

  const [marketingMetrics] = useState({
    series: [{
      name: 'Marketplace Campaign Performance',
      data: [85, 75, 82, 90]
    }],
    categories: ['Amazon Brand Store', 'Flipkart Brand Day', 'BigBasket Spotlight', 'JioMart Featured']
  });

  const [regionData] = useState({
    series: [{
      name: 'Distribution Volume',
      data: [14500, 12300, 15800, 13400, 11600]
    }],
    categories: ['North', 'South', 'East', 'West', 'Central']
  });

  const [marketplaceData] = useState({
    series: [{
      name: 'Units Shipped',
      data: [84500, 62300, 45100, 28400]
    }],
    labels: ['Amazon', 'Flipkart', 'BigBasket', 'JioMart']
  });

  const [satisfactionData] = useState({
    series: [94, 88, 92, 96],
    labels: ['Marketplace Compliance', 'Inventory Accuracy', 'On-Time Fulfillment', 'Quality Standards']
  });

  const [fulfillmentMetrics] = useState({
    fillRate: 96.5,
    purchaseOrders: 1245,
    onTimeDelivery: 98.2,
    inventoryAccuracy: 99.1,
    pendingShipments: 156,
    averageProcessingTime: 1.8
  });

  // Enhanced KPI Cards Component
  const KPICard = ({ title, value, trend, icon: Icon, prefix = '', suffix = '' }) => {
    const valueRef = useRef(null);

    useEffect(() => {
      if (valueRef.current) {
        animateValue(valueRef.current, 0, value, 2000);
      }
    }, [value]);

    return (
      <div className="metric-card animate-slide-up">
        <div className="metric-header">
          <div className="metric-icon">
            <Icon size={24} />
          </div>
          <div className={`metric-trend ${trend >= 0 ? 'positive' : 'negative'}`}>
            {trend >= 0 ? <ArrowUp size={16} /> : <ArrowDown size={16} />}
            <span>{Math.abs(trend)}%</span>
          </div>
        </div>
        <div className="metric-content">
          <h3>{title}</h3>
          <p className="metric-value" ref={valueRef}>
            {prefix}0{suffix}
          </p>
          <span className="metric-period">Last 30 days</span>
        </div>
      </div>
    );
  };

  // Enhanced chart options
  const getChartOptions = (type, title) => ({
    chart: {
      type,
      toolbar: { show: false },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        }
      }
    },
    colors: ['#4F46E5', '#06B6D4', '#8B5CF6', '#EC4899', '#F59E0B'],
    theme: {
      mode: 'light',
      palette: 'palette1'
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        columnWidth: '60%',
        distributed: true
      },
      pie: {
        donut: {
          size: '70%'
        }
      }
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.5,
        opacityFrom: 0.8,
        opacityTo: 0.2
      }
    }
  });

  // Revenue Chart Configuration
  const revenueChartOptions = {
    chart: {
      type: 'area',
      height: 350,
      toolbar: { show: false },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        }
      }
    },
    dataLabels: { enabled: false },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100]
      }
    },
    theme: { mode: 'light' }
  };

  // Add custom chart options for marketplace performance
  const marketplaceChartOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        columnWidth: '60%',
        distributed: true,
        gradient: {
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: ['#60a5fa'],
          inverseColors: false,
          opacityFrom: 0.8,
          opacityTo: 1,
          stops: [0, 100]
        },
      }
    },
    colors: ['#3b82f6', '#4f46e5', '#06b6d4', '#8b5cf6'],
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: marketplaceData.labels,
      labels: {
        style: {
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      title: {
        text: 'Units Shipped'
      }
    },
    legend: {
      show: false
    }
  };

  // Add custom chart options for marketing performance
  const marketingChartOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        }
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '55%',
        distributed: true,
        gradient: {
          type: "vertical",
          shadeIntensity: 1,
          gradientToColors: ['#4F46E5'],
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 1,
        }
      },
    },
    colors: ['#818CF8', '#6366F1', '#4F46E5', '#4338CA'],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
        fontWeight: 600,
        colors: ['#fff']
      },
      formatter: function (val) {
        return val + '%';
      },
      offsetY: -20,
    },
    xaxis: {
      categories: marketingMetrics.categories,
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val + '%';
        }
      }
    },
    grid: {
      borderColor: '#f1f1f1',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + '% Performance';
        }
      }
    }
  };

  // Add custom chart options for regional performance
  const regionalChartOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '55%',
        distributed: true,
        gradient: {
          type: "vertical",
          shadeIntensity: 1,
          gradientToColors: ['#4F46E5'],
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 1,
        }
      }
    },
    colors: ['#818CF8', '#6366F1', '#4F46E5', '#4338CA', '#3730A3'],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
        fontWeight: 600,
        colors: ['#fff']
      },
      formatter: function (val) {
        return val.toLocaleString();
      },
      offsetY: -20,
    },
    xaxis: {
      categories: regionData.categories,
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      title: {
        text: 'Units',
        style: {
          fontSize: '14px',
          fontWeight: 500
        }
      },
      labels: {
        formatter: function (val) {
          return val.toLocaleString();
        }
      }
    },
    grid: {
      borderColor: '#f1f1f1',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toLocaleString() + ' units';
        }
      }
    }
  };

  // Update chart configurations with all necessary data
  const [chartData] = useState({
    platformDistribution: {
      series: [45, 25, 20, 10],
      labels: ['Amazon', 'Flipkart', 'BigBasket', 'JioMart']
    },
    brandPerformance: {
      series: [75, 85, 90, 70],
      labels: ['Brand Visibility', 'Stock Availability', 'Sell-through Rate', 'Market Share']
    },
    revenueTrends: {
      series: [{
        name: 'Revenue',
        data: [28000, 42000, 35000, 48000, 38000, 52000, 45000]
      }, {
        name: 'Target',
        data: [25000, 35000, 35000, 40000, 40000, 45000, 45000]
      }],
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']
    },
    trendData: {
      series: [
        {
          name: 'Amazon',
          data: [65, 72, 78, 75, 82, 88, 85]
        }, 
        {
          name: 'Flipkart',
          data: [45, 52, 58, 55, 62, 68, 65]
        }, 
        {
          name: 'BigBasket',
          data: [35, 42, 48, 45, 52, 58, 55]
        }
      ],
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']
    }
  });

  // Now use the baseLineChartOptions
  const lineChartOptions = {
    ...baseLineChartOptions,
    // Add any component-specific overrides here if needed
  };

  return (
    <div className="dashboard-content">
      <div className="summary-scroll-container">
        <div className="executive-header">
          <div className="header-content animate-fade-in">
            <h1>E-Commerce Performance Dashboard</h1>
            <p>Comprehensive overview across all marketplaces</p>
          </div>
          <div className="quick-stats">
            <div className="stat-item">
              <ShoppingCart size={20} />
              <span>Today's Orders: 245</span>
            </div>
            <div className="stat-item">
              <DollarSign size={20} />
              <span>Today's Revenue: $28,590</span>
            </div>
            <div className="stat-item">
              <Users size={20} />
              <span>Active Users: 1,245</span>
            </div>
            <div className="stat-item">
              <Star size={20} />
              <span>Avg Rating: 4.8/5</span>
            </div>
          </div>
        </div>

        {/* KPI Section */}
        <div className="metrics-container">
          <div className="section-title">
            <Activity size={20} />
            <h2>Brand Performance Indicators</h2>
          </div>
          <div className="metrics">
            <KPICard 
              title="Total Shipments" 
              value={kpiData.totalShipments}
              trend={12.5}
              icon={Package}
              prefix="Units "
            />
            <KPICard 
              title="Purchase Orders" 
              value={kpiData.purchaseOrders}
              trend={8.3}
              icon={ShoppingBag}
            />
            <KPICard 
              title="Marketplace Share" 
              value={kpiData.marketplaceShare}
              trend={1.8}
              icon={PieChart}
              suffix="%"
            />
            <KPICard 
              title="Brand Performance" 
              value={kpiData.brandPerformance}
              trend={15.2}
              icon={Award}
              suffix="%"
            />
          </div>
        </div>

        {/* Order Fulfillment Section - Moved directly below KPIs */}
        <div className="order-fulfillment-container">
          <div className="section-title">
            <Package size={20} />
            <h2>Order Fulfillment Performance</h2>
          </div>
          <div className="fulfillment-metrics">
            <div className="metric-card">
              <div className="metric-header">
                <div className="metric-icon">
                  <Target size={24} />
                </div>
                <div className="metric-trend positive">
                  <ArrowUp size={16} />
                  <span>2.5%</span>
                </div>
              </div>
              <div className="metric-content">
                <h3>Fill Rate</h3>
                <p className="metric-value">{fulfillmentMetrics.fillRate}%</p>
                <span className="metric-period">Current Month</span>
              </div>
            </div>

            <div className="metric-card">
              <div className="metric-header">
                <div className="metric-icon">
                  <ShoppingCart size={24} />
                </div>
                <div className="metric-trend positive">
                  <ArrowUp size={16} />
                  <span>12%</span>
                </div>
              </div>
              <div className="metric-content">
                <h3>Purchase Orders</h3>
                <p className="metric-value">{fulfillmentMetrics.purchaseOrders}</p>
                <span className="metric-period">Active POs</span>
              </div>
            </div>

            <div className="metric-card">
              <div className="metric-header">
                <div className="metric-icon">
                  <Truck size={24} />
                </div>
                <div className="metric-trend positive">
                  <ArrowUp size={16} />
                  <span>1.8%</span>
                </div>
              </div>
              <div className="metric-content">
                <h3>On-Time Delivery</h3>
                <p className="metric-value">{fulfillmentMetrics.onTimeDelivery}%</p>
                <span className="metric-period">Last 30 days</span>
              </div>
            </div>

            <div className="metric-card">
              <div className="metric-header">
                <div className="metric-icon">
                  <BarChart2 size={24} />
                </div>
                <div className="metric-trend positive">
                  <ArrowUp size={16} />
                  <span>0.5%</span>
                </div>
              </div>
              <div className="metric-content">
                <h3>Inventory Accuracy</h3>
                <p className="metric-value">{fulfillmentMetrics.inventoryAccuracy}%</p>
                <span className="metric-period">Current Status</span>
              </div>
            </div>
          </div>
        </div>

        {/* Charts Grid */}
        <div className="charts-grid">
          {/* Revenue Trend */}
          <div className="chart-card animate-slide-up">
            <div className="chart-header">
              <div className="chart-title">
                <TrendingUp size={18} />
                <h3>Revenue Trends</h3>
              </div>
              <select className="chart-period-selector">
                <option>Last 7 Days</option>
                <option>Last 30 Days</option>
                <option>Last 90 Days</option>
              </select>
            </div>
            <ReactApexChart
              options={revenueChartOptions}
              series={salesData.series}
              type="area"
              height={350}
            />
          </div>

          {/* Regional Performance */}
          <div className="regional-section animate-slide-up">
            <div className="section-title">
              <Map size={20} />
              <h2>Regional Distribution Performance</h2>
            </div>
            <div className="chart-card">
              <ReactApexChart
                options={regionalChartOptions}
                series={regionData.series}
                type="bar"
                height={350}
              />
            </div>
          </div>
        </div>

        {/* Second Row of Charts */}
        <div className="charts-container">
          <div className="chart-grid">
            <div className="chart-card animate-fade-scale">
              <h3>Marketplace Distribution</h3>
              <ReactApexChart
                options={{
                  ...marketplaceChartOptions,
                  colors: ['#3b82f6', '#4f46e5', '#06b6d4', '#8b5cf6']
                }}
                series={chartData.platformDistribution.series}
                type="donut"
                height={350}
              />
            </div>
            <div className="chart-card animate-fade-scale">
              <h3>Brand Performance Metrics</h3>
              <ReactApexChart
                options={{
                  ...marketplaceChartOptions,
                  colors: ['#60a5fa', '#34d399', '#fbbf24', '#f87171']
                }}
                series={chartData.brandPerformance.series}
                type="donut"
                height={350}
              />
            </div>
          </div>
          
          <div className="trend-chart-card">
            <h3>Brand Performance Trend</h3>
            <ReactApexChart
              options={lineChartOptions}
              series={chartData.trendData.series}
              type="line"
              height={350}
            />
          </div>
        </div>

        {/* Marketing Campaign Performance */}
        <div className="marketing-section animate-slide-up">
          <div className="section-title">
            <Award size={20} />
            <h2>Marketplace Campaign Performance</h2>
          </div>
          <div className="campaign-metrics">
            <div className="campaign-chart">
              <ReactApexChart
                options={marketingChartOptions}
                series={marketingMetrics.series}
                type="bar"
                height={350}
              />
            </div>
            <div className="campaign-stats">
              <div className="stat-card">
                <h4>Best Performing Platform</h4>
                <p>JioMart Featured</p>
                <span className="stat-value">90% Success</span>
              </div>
              <div className="stat-card">
                <h4>Total Reach</h4>
                <p>2.4M Views</p>
                <span className="trend positive">+12% vs last month</span>
              </div>
              <div className="stat-card">
                <h4>Conversion Rate</h4>
                <p>3.2%</p>
                <span className="trend positive">+0.5% vs target</span>
              </div>
            </div>
          </div>
        </div>

        {/* Marketplace Performance */}
        <div className="marketplace-section animate-slide-up">
          <div className="section-title">
            <Globe size={20} />
            <h2>Marketplace Performance</h2>
          </div>
          <div className="marketplace-grid">
            <div className="chart-card">
              <ReactApexChart
                options={marketplaceChartOptions}
                series={marketplaceData.series}
                type="bar"
                height={300}
              />
            </div>
            <div className="marketplace-metrics">
              {marketplaceData.labels.map((platform, index) => (
                <div key={platform} className="platform-card">
                  <h4>{platform}</h4>
                  <p className="platform-value">
                    ${marketplaceData.series[0].data[index].toLocaleString()}
                  </p>
                  <div className="platform-stats">
                    <span>Orders: 1.2k</span>
                    <span>Rating: 4.8</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Customer Satisfaction */}
        <div className="satisfaction-section animate-slide-up">
          <div className="section-title">
            <Award size={20} />
            <h2>Brand Performance Metrics</h2>
          </div>
          <div className="satisfaction-grid">
            <div className="chart-card">
              <ReactApexChart
                options={{
                  ...getChartOptions('radialBar', 'Performance Scores'),
                  colors: ['#3b82f6', '#4f46e5', '#06b6d4', '#8b5cf6'],
                  plotOptions: {
                    radialBar: {
                      dataLabels: {
                        name: {
                          fontSize: '14px',
                          color: '#64748b'
                        },
                        value: {
                          fontSize: '20px',
                          fontWeight: 600,
                          color: '#1e293b'
                        }
                      },
                      track: {
                        background: '#f1f5f9'
                      }
                    }
                  }
                }}
                series={satisfactionData.series}
                type="radialBar"
                height={350}
              />
            </div>
            <div className="satisfaction-metrics">
              {satisfactionData.labels.map((label, index) => (
                <div key={label} className="metric-pill brand-metric">
                  <span className="label">{label}</span>
                  <span className="value">{satisfactionData.series[index]}%</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        
      </div>
    </div>
  );
};

export default ExecutiveSummary; 