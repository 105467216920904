import React, { useState } from 'react';
import { 
  Bell, 
  User,
  Mail,
  Moon,
  Sun,
  Save,
  Camera,
  Palette,
  AlertCircle,
  ShoppingCart,
  FileText,
  Lock
} from 'lucide-react';
import './settings.css';

const SettingsPopup = ({ isOpen, onClose, user }) => {
  const [activeTab, setActiveTab] = useState('profile');
  const [theme, setTheme] = useState('light');
  const [language, setLanguage] = useState('en');
  
  // Profile image handling
  const [profileImage, setProfileImage] = useState(user?.imageUrl || '');
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [notifications] = useState({
    systemAlerts: true,
    reportNotifications: true
  });

  const [profileForm] = useState({
    name: user?.name || '',
    email: user?.email || '',
    theme: 'light',
    language: 'en',
    notifications: true,
    marketingEmails: false
  });

  return (
    <div className="settings-popup-overlay" onClick={(e) => {
      if (e.target === e.currentTarget) onClose();
    }}>
      <div className="settings-popup">
        <div className="settings-header">
          <h2>Settings</h2>
          <button className="close-btn" onClick={onClose}>×</button>
        </div>
        
        <div className="settings-container">
          <div className="settings-sidebar">
            <div className="user-preview">
              <div className="profile-image-container">
                <img src={profileImage || 'default-avatar.png'} alt="Profile" />
                <label className="image-upload-btn">
                  <Camera size={16} />
                  <input type="file" accept="image/*" onChange={handleImageUpload} hidden />
                </label>
              </div>
              <div className="user-info">
                <h4>{profileForm.firstName} {profileForm.lastName}</h4>
                <span>{profileForm.role}</span>
              </div>
            </div>

            <div className="tabs-container">
              <button className={`tab-btn ${activeTab === 'profile' ? 'active' : ''}`}
                onClick={() => setActiveTab('profile')}>
                <User size={18} />
                Profile
              </button>
              <button className={`tab-btn ${activeTab === 'notifications' ? 'active' : ''}`}
                onClick={() => setActiveTab('notifications')}>
                <Bell size={18} />
                Notifications
              </button>
              <button className={`tab-btn ${activeTab === 'security' ? 'active' : ''}`}
                onClick={() => setActiveTab('security')}>
                <Lock size={18} />
                Security
              </button>
              <button className={`tab-btn ${activeTab === 'preferences' ? 'active' : ''}`}
                onClick={() => setActiveTab('preferences')}>
                <Palette size={18} />
                Preferences
              </button>
            </div>
          </div>

          <div className="settings-content">
            {activeTab === 'profile' && (
              <div className="settings-section">
                <h3>Profile Settings</h3>
                <div className="profile-form">
                  <div className="form-row">
                    <div className="form-group">
                      <label>First Name</label>
                      <input type="text" name="firstName" value={profileForm.firstName} />
                    </div>
                    <div className="form-group">
                      <label>Last Name</label>
                      <input type="text" name="lastName" value={profileForm.lastName} />
                    </div>
                  </div>
                  
                  <div className="form-row">
                    <div className="form-group">
                      <label>Email</label>
                      <input type="email" name="email" value={profileForm.email} />
                    </div>
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input type="tel" name="phoneNumber" value={profileForm.phoneNumber} />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label>Department</label>
                      <input type="text" name="department" value={profileForm.department} />
                    </div>
                    <div className="form-group">
                      <label>Role</label>
                      <input type="text" value={profileForm.role} disabled />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'notifications' && (
              <div className="settings-section">
                <h3>Notification Preferences</h3>
                <div className="notification-groups">
                  <div className="notification-group">
                    <h4>General Notifications</h4>
                    <div className="setting-items">
                      <div className="setting-item">
                        <div className="setting-info">
                          <Mail size={18} />
                          <div>
                            <span>Email Notifications</span>
                            <p>Receive email updates about your account</p>
                          </div>
                        </div>
                        <label className="switch">
                          <input type="checkbox" checked={notifications.emailNotifications} />
                          <span className="slider"></span>
                        </label>
                      </div>

                      <div className="setting-item">
                        <div className="setting-info">
                          <ShoppingCart size={18} />
                          <div>
                            <span>Order Updates</span>
                            <p>Get notified about order status changes</p>
                          </div>
                        </div>
                        <label className="switch">
                          <input type="checkbox" checked={notifications.orderUpdates} />
                          <span className="slider"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="notification-group">
                    <h4>System Notifications</h4>
                    <div className="setting-items">
                      <div className="setting-item">
                        <div className="setting-info">
                          <AlertCircle size={18} />
                          <div>
                            <span>System Alerts</span>
                            <p>Important system updates and maintenance</p>
                          </div>
                        </div>
                        <label className="switch">
                          <input type="checkbox" checked={notifications.systemAlerts} />
                          <span className="slider"></span>
                        </label>
                      </div>

                      <div className="setting-item">
                        <div className="setting-info">
                          <FileText size={18} />
                          <div>
                            <span>Report Notifications</span>
                            <p>Get notified when reports are generated</p>
                          </div>
                        </div>
                        <label className="switch">
                          <input type="checkbox" checked={notifications.reportNotifications} />
                          <span className="slider"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'preferences' && (
              <div className="settings-section">
                <h3>System Preferences</h3>
                <div className="preferences-container">
                  <div className="preference-group">
                    <h4>Appearance</h4>
                    <div className="theme-selector">
                      <button className={`theme-btn ${theme === 'light' ? 'active' : ''}`}
                        onClick={() => setTheme('light')}>
                        <Sun size={18} />
                        Light
                      </button>
                      <button className={`theme-btn ${theme === 'dark' ? 'active' : ''}`}
                        onClick={() => setTheme('dark')}>
                        <Moon size={18} />
                        Dark
                      </button>
                    </div>
                  </div>

                  <div className="preference-group">
                    <h4>Language & Region</h4>
                    <div className="form-group">
                      <label>Language</label>
                      <select value={language} onChange={(e) => setLanguage(e.target.value)}>
                        <option value="en">English</option>
                        <option value="es">Español</option>
                        <option value="fr">Français</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Time Zone</label>
                      <select value={profileForm.timezone}>
                        <option value="UTC">UTC</option>
                        <option value="EST">Eastern Time</option>
                        <option value="PST">Pacific Time</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="settings-actions">
              <button className="cancel-btn" onClick={onClose}>Cancel</button>
              <button className="save-btn">
                <Save size={18} />
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPopup;