import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { 
  TrendingUp, DollarSign,
  Target, ShoppingCart, Package,
  TrendingDown, Award,
  Users, ShoppingBag
} from 'lucide-react';
import './SalesAnalytics.css';

// Add salesFunnelData configuration
const salesFunnelData = {
  series: [{
    name: 'Conversion',
    data: [1000, 800, 600, 400, 200]
  }],
  options: {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: { show: false }
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        horizontal: true,
        distributed: true,
        barHeight: '80%',
      }
    },
    colors: ['#4F46E5', '#818CF8', '#06B6D4', '#34D399', '#10B981'],
    dataLabels: {
      enabled: true,
      formatter: function(val) {
        return val.toLocaleString()
      },
      style: {
        fontSize: '12px'
      }
    },
    xaxis: {
      categories: ['Visits', 'Product Views', 'Add to Cart', 'Checkout', 'Purchase']
    },
    grid: {
      show: false
    }
  }
};

// Add new chart configurations
const categoryPerformanceData = {
  series: [{
    name: 'Sales Volume (Kg)',
    data: [2450, 1850, 1220, 985, 760, 580]
  }, {
    name: 'Revenue (₹)',
    data: [245000, 185000, 122000, 98000, 76000, 58000]
  }],
  options: {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: { show: false },
      stacked: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 8,
      },
    },
    colors: ['#4F46E5', '#06B6D4'],
    dataLabels: { enabled: false },
    xaxis: {
      categories: ['5kg Pack', '2kg Pack', '1kg Pack', '10kg Pack', '25kg Pack', 'Trial Pack'],
    }
  }
};

const geographicData = {
  series: [44, 55, 13, 43, 22],
  options: {
    chart: {
      type: 'donut',
      height: 350
    },
    labels: ['North', 'South', 'East', 'West', 'Central'],
    colors: ['#4F46E5', '#06B6D4', '#10B981', '#8B5CF6', '#F59E0B'],
    legend: {
      position: 'bottom'
    }
  }
};

const topPerformingRice = {
  series: [{
    name: 'Sales Volume (Kg)',
    data: [2450, 1850, 1220, 985, 760]
  }],
  options: {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: { show: false }
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        horizontal: true,
        distributed: true,
        barHeight: '70%',
      }
    },
    colors: ['#4F46E5', '#818CF8', '#06B6D4', '#34D399', '#10B981'],
    dataLabels: {
      enabled: true,
      formatter: function(val) {
        return val + ' kg'
      }
    },
    xaxis: {
      categories: ['Rozana Classic Basmati', 'Premium Sella Basmati', 'Mini Mogra Rice', 'Premium Kolam', 'Steam Basmati']
    }
  }
};

const SalesAnalytics = () => {
  const [kpiMetrics] = useState({
    totalRevenue: 2845600,
    averageOrderValue: 1250,
    conversionRate: 3.8,
    customerLifetimeValue: 15800,
    revenueGrowth: 12.5,
    repeatPurchaseRate: 45.2
  });

  // Add new metrics state
  const [salesMetrics] = useState({
    topSellingProducts: [
      { name: 'Rozana Classic Basmati', sales: 1234, growth: 15.2 },
      { name: 'Premium Sella Basmati', sales: 956, growth: 8.7 },
      { name: 'Mini Mogra Rice', sales: 823, growth: -2.3 }
    ],
    platformMetrics: {
      amazon: { value: 32, growth: 15.5, orders: 1850, volume: "850 kg" },
      flipkart: { value: 28, growth: 8.2, orders: 1420, volume: "680 kg" },
      blinkit: { value: 22, growth: 12.5, orders: 950, volume: "410 kg" },
      zepto: { value: 18, growth: 22.1, orders: 780, volume: "320 kg" }
    }
  });

  // KPI Card Component
  const KPICard = ({ title, value, trend, icon: Icon, prefix = '', suffix = '' }) => (
    <div className="sales-metric-card">
      <div className="metric-header">
        <div className="metric-icon">
          <Icon size={24} />
        </div>
        <div className={`growth-indicator ${trend >= 0 ? 'positive' : 'negative'}`}>
          {trend >= 0 ? <TrendingUp size={16} /> : <TrendingDown size={16} />}
          <span>{Math.abs(trend)}%</span>
        </div>
      </div>
      <div className="metric-content">
        <h3>{title}</h3>
        <p className="metric-value">{prefix}{value.toLocaleString()}{suffix}</p>
        <span className="metric-period">Last 30 days</span>
      </div>
    </div>
  );

  return (
    <div className="sales-analytics-container">
      {/* New Executive-style Header */}
      <div className="sales-executive-header">
        <div className="header-content animate-fade-in">
          <h1>Rice & Commodities Trading Analytics</h1>
          <p>Real-time insights across all trading platforms</p>
        </div>
        <div className="quick-stats">
          <div className="stat-item">
            <Package size={20} />
            <span>Today's Volume: 850 Kg</span>
          </div>
          <div className="stat-item">
            <DollarSign size={20} />
            <span>Today's Revenue: ₹1.2 Cr</span>
          </div>
          <div className="stat-item">
            <ShoppingCart size={20} />
            <span>Active Orders: 284</span>
          </div>
          <div className="stat-item">
            <TrendingUp size={20} />
            <span>Avg Price/Kg: ₹185</span>
          </div>
        </div>
      </div>

      {/* Header Section */}
      <div className="sales-header">
        <h1>Sales Analytics</h1>
        <div className="period-selector">
          <select>
            <option>Last 7 Days</option>
            <option>Last 30 Days</option>
            <option>Last 90 Days</option>
          </select>
        </div>
      </div>

      {/* KPI Metrics Grid */}
      <div className="time-metrics-grid">
        <KPICard 
          title="Total Revenue" 
          value={kpiMetrics.totalRevenue}
          trend={12.5}
          icon={DollarSign}
          prefix="₹"
        />
        <KPICard 
          title="Average Order Value" 
          value={kpiMetrics.averageOrderValue}
          trend={8.3}
          icon={ShoppingBag}
          prefix="₹"
        />
        <KPICard 
          title="Conversion Rate" 
          value={kpiMetrics.conversionRate}
          trend={1.8}
          icon={Target}
          suffix="%"
        />
        <KPICard 
          title="Customer LTV" 
          value={kpiMetrics.customerLifetimeValue}
          trend={15.2}
          icon={Award}
          prefix="₹"
        />
      </div>

      {/* Update Channel Distribution Section to Platform Distribution */}
      <div className="channel-distribution-section">
        <h2>E-commerce Platform Distribution</h2>
        <div className="channel-metrics-grid">
          {Object.entries(salesMetrics.platformMetrics).map(([platform, data]) => (
            <div key={platform} className="channel-card">
              <h3>{platform.charAt(0).toUpperCase() + platform.slice(1)}</h3>
              <div className="channel-value">{data.value}%</div>
              <div className={`channel-growth ${data.growth >= 0 ? 'positive' : 'negative'}`}>
                {data.growth >= 0 ? <TrendingUp size={16} /> : <TrendingDown size={16} />}
                <span>{Math.abs(data.growth)}% vs last month</span>
              </div>
              <div className="channel-orders">
                <Package size={16} />
                <span>{data.volume} sold</span>
              </div>
              <div className="channel-orders">
                <ShoppingCart size={16} />
                <span>{data.orders.toLocaleString()} orders</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Add Top Selling Products Section */}
      <div className="top-products-section">
        <h2>Top Selling Products</h2>
        <div className="products-grid">
          {salesMetrics.topSellingProducts.map((product, index) => (
            <div key={index} className="product-card">
              <div className="product-rank">{index + 1}</div>
              <div className="product-details">
                <h3>{product.name}</h3>
                <p className="product-sales">₹{product.sales.toLocaleString()}</p>
                <div className={`product-growth ${product.growth >= 0 ? 'positive' : 'negative'}`}>
                  {product.growth >= 0 ? <TrendingUp size={16} /> : <TrendingDown size={16} />}
                  <span>{Math.abs(product.growth)}%</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Enhanced Charts Grid */}
      <div className="sales-charts-grid">
        {/* Sales Funnel Chart */}
        <div className="chart-card">
          <h2>Sales Funnel</h2>
          <ReactApexChart
            options={salesFunnelData.options}
            series={salesFunnelData.series}
            type="bar"
            height={350}
          />
        </div>

        {/* Revenue Trend Chart */}
        <div className="chart-card">
          <h2>Revenue Trend</h2>
          <ReactApexChart
            options={{
              ...salesFunnelData.options,
              chart: {
                type: 'area',
                height: 350,
                toolbar: { show: false }
              },
              xaxis: {
                categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
              }
            }}
            series={[{
              name: 'Revenue',
              data: [31000, 40000, 28000, 51000, 42000, 109000, 100000]
            }]}
            type="area"
            height={350}
          />
        </div>

        {/* Add Category Performance Chart */}
        <div className="chart-card">
          <h2>Category Performance</h2>
          <ReactApexChart
            options={categoryPerformanceData.options}
            series={categoryPerformanceData.series}
            type="bar"
            height={350}
          />
        </div>

        {/* Add Geographic Distribution Chart */}
        <div className="chart-card">
          <h2>Geographic Distribution</h2>
          <ReactApexChart
            options={geographicData.options}
            series={geographicData.series}
            type="donut"
            height={350}
          />
        </div>

        {/* Add Top Performing Rice Variants Chart */}
        <div className="chart-card">
          <h2>Top Performing Rice Variants</h2>
          <ReactApexChart
            options={topPerformingRice.options}
            series={topPerformingRice.series}
            type="bar"
            height={350}
          />
        </div>
      </div>

      {/* Add Performance Metrics */}
      <div className="performance-metrics-section">
        <h2>Performance Metrics</h2>
        <div className="metrics-grid">
          <div className="metric-tile">
            <div className="metric-icon">
              <ShoppingCart size={24} />
            </div>
            <div className="metric-details">
              <h3>Average Order Processing Time</h3>
              <p>2.5 hours</p>
              <span className="trend positive">↓ 15% faster than last month</span>
            </div>
          </div>
          <div className="metric-tile">
            <div className="metric-icon">
              <Users size={24} />
            </div>
            <div className="metric-details">
              <h3>Customer Satisfaction</h3>
              <p>4.8/5.0</p>
              <span className="trend positive">↑ 0.2 points increase</span>
            </div>
          </div>
          <div className="metric-tile">
            <div className="metric-icon">
              <Package size={24} />
            </div>
            <div className="metric-details">
              <h3>Return Rate</h3>
              <p>3.2%</p>
              <span className="trend negative">↓ 0.5% increase</span>
            </div>
          </div>
        </div>
      </div>

      {/* Insights Section */}
      <div className="insights-section">
        <h2>Key Insights</h2>
        <div className="insights-grid">
          <div className="insight-card">
            <div className="insight-icon">
              <TrendingUp size={24} />
            </div>
            <div className="insight-content">
              <h3>Peak Sales Hours</h3>
              <p>Sales peak between 2PM - 5PM, suggesting optimal advertising windows</p>
              <div className="insight-metric">
                <span>Conversion Rate</span>
                <strong>+4.2%</strong>
              </div>
            </div>
          </div>
          <div className="insight-card">
            <div className="insight-icon">
              <Users size={24} />
            </div>
            <div className="insight-content">
              <h3>Customer Retention</h3>
              <p>Repeat customer rate increased by 15% this month</p>
              <div className="insight-metric">
                <span>Retention Rate</span>
                <strong>68%</strong>
              </div>
            </div>
          </div>
          <div className="insight-card">
            <div className="insight-icon">
              <Package size={24} />
            </div>
            <div className="insight-content">
              <h3>Stock Alerts</h3>
              <p>3 top-selling products are running low on inventory</p>
              <div className="insight-metric">
                <span>Alert Level</span>
                <strong>Critical</strong>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* New Quick Overview Section */}
      <div className="quick-overview-section">
        <div className="overview-header">
          <h2>Rice Trading Analytics</h2>
          <div className="trading-highlights">
            <div className="highlight-item">
              <span>Today's Trading Volume</span>
              <h3>850 Kg</h3>
              <p className="trend positive">↑ 15% vs Yesterday</p>
            </div>
            <div className="highlight-item">
              <span>Average Price/Kg</span>
              <h3>₹185</h3>
              <p className="trend positive">↑ 3.2% vs Last Week</p>
            </div>
            <div className="highlight-item">
              <span>Active Orders</span>
              <h3>284</h3>
              <p className="trend neutral">Processing</p>
            </div>
          </div>
        </div>
      </div>

      {/* Add Product Performance Section */}
      <div className="product-performance-section">
        <h2>Product Performance</h2>
        <div className="performance-cards">
          <div className="performance-card rice">
            <div className="card-header">
              <h3>Premium Rice</h3>
              <span className="badge trending">High Demand</span>
            </div>
            <div className="metrics-row">
              <div className="metric">
                <span>Volume Traded</span>
                <h4>1,250 Tons</h4>
                <p className="trend positive">↑ 18%</p>
              </div>
              <div className="metric">
                <span>Revenue</span>
                <h4>₹6.2 Cr</h4>
                <p className="trend positive">↑ 22%</p>
              </div>
            </div>
          </div>
          <div className="performance-card spices">
            <div className="card-header">
              <h3>Biryani Masala</h3>
              <span className="badge featured">Featured</span>
            </div>
            <div className="metrics-row">
              <div className="metric">
                <span>Units Sold</span>
                <h4>45,000</h4>
                <p className="trend positive">↑ 12%</p>
              </div>
              <div className="metric">
                <span>Revenue</span>
                <h4>₹2.8 Cr</h4>
                <p className="trend positive">↑ 15%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesAnalytics; 