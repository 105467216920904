import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { fetchDataFromAPI, fetchPodata, approveOrders, editOrderItem, fetchPlatforms } from '../../routes/AppRoutes';
import * as XLSX from 'xlsx';
import { ChevronDown, ChevronRight, RotateCw, RefreshCw, Check, Download, X, Search } from 'react-feather';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './PoTable.css';
import { AlertCard } from '../Assets/Dialog';
import debounce from 'lodash/debounce';

const Modal = ({ show, onClose, children }) => {
  if (!show) return null;
  return (
    <div className="modal-overlay" style={{ zIndex: 9999 }}>
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="modal-title">Edit Item Details</h2>
          <button onClick={onClose} className="modal-close">
            <X size={24} />
          </button>
        </div>
        <div className="modal-body">
          <div className="modal-scroll-container">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const PoTable = ({ platform }) => {
  // First, declare all state variables
  const [recordsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [platforms, setPlatforms] = useState([]);
  const [orders, setOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [columns, setColumns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [activeToggle, setActiveToggle] = useState(null);
  const [toppledData, setToppledData] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [statusFilter, setStatusFilter] = useState('');
  const [poNumberSearch, setPoNumberSearch] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [platformLoadingStates, setPlatformLoadingStates] = useState({});
  const [alert, setAlert] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);

  const previousPlatformRef = useRef(platform);

  // Add new ref and state for scroll handling
  const scrollableRef = useRef(null);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  // Define resetTableState before using it in other functions
  const resetTableState = useCallback(() => {
    setActiveToggle(null);
    setToppledData({});
    setSelectAll(false);
    setStatusFilter('');
    setPoNumberSearch('');
    setStartDate(null);
    setEndDate(null);
    setColumns([]);
    setAllOrders([]);
    setOrders([]);
    setTotalPages(0);
    setCurrentPage(1);
  }, []);

  // Define loadOrders after resetTableState
  const loadOrders = useCallback(async (platformToLoad) => {
    if (!platformToLoad) return;
    
    try {
      const response = await fetchDataFromAPI(platformToLoad);
      
      if (!response || !response.data) {
        throw new Error('Invalid response format');
      }

      let orderData = Array.isArray(response.data) ? response.data : [response.data];
      
      if (orderData.length === 0) {
        setAlert({
          type: 'info',
          message: 'No orders found for this platform.',
          title: 'No Data'
        });
        resetTableState();
        return;
      }

      const validatedOrders = orderData
        .filter(order => order && typeof order === 'object')
        .map(order => ({
          ...order,
          selected: false,
          STATUS: order.STATUS || 'Pending',
          PoDate: order.PoDate ? new Date(order.PoDate).toISOString() : null
        }));

      if (validatedOrders.length > 0) {
        const validColumns = Object.keys(validatedOrders[0])
          .filter(key => key !== 'selected');
        setColumns(validColumns);
      }

      setAllOrders(validatedOrders);
      setOrders(validatedOrders);
      setTotalPages(Math.ceil(validatedOrders.length / recordsPerPage));
      setCurrentPage(1);

    } catch (error) {
      console.error('Error loading orders:', error);
      resetTableState();
      setAlert({
        type: 'error',
        message: 'Failed to load orders. Please try again.',
        title: 'Error'
      });
    }
  }, [recordsPerPage, resetTableState]);

  // Platform change handler
  const handlePlatformChange = async (newPlatform) => {
    if (newPlatform === selectedPlatform || isLoading) return;

    try {
      setPlatformLoadingStates(prev => ({
        ...prev,
        [newPlatform]: true
      }));
      setIsLoading(true);

      previousPlatformRef.current = selectedPlatform;
      setSelectedPlatform(newPlatform);
      
      resetTableState();
      await loadOrders(newPlatform);

    } catch (error) {
      console.error('Error switching platform:', error);
      setSelectedPlatform(previousPlatformRef.current);
      
      setAlert({
        type: 'error',
        message: 'Failed to load platform data. Please try again.',
        title: 'Error'
      });
    } finally {
      setPlatformLoadingStates(prev => ({
        ...prev,
        [newPlatform]: false
      }));
      setIsLoading(false);
    }
  };

  // Cleanup effect
  useEffect(() => {
    return () => {
      resetTableState();
    };
  }, [resetTableState]);

  // Platform initialization effect
  useEffect(() => {
    const loadPlatforms = async () => {
      try {
        const response = await fetchPlatforms();
        if (response.success && Array.isArray(response.data)) {
          const platformNames = response.data.map(p => p.platform_name);
          setPlatforms(platformNames);
          
          if (!selectedPlatform && platformNames.length > 0) {
            const initialPlatform = platform || platformNames[0];
            setSelectedPlatform(initialPlatform);
            await loadOrders(initialPlatform);
          }
        }
      } catch (error) {
        console.error('Error loading platforms:', error);
        setAlert({
          type: 'error',
          message: 'Failed to load platforms. Please refresh the page.',
          title: 'Error'
        });
      }
    };

    loadPlatforms();
  }, [platform, loadOrders, selectedPlatform]);

  // Platform tabs renderer - restored to original UI
  const renderPlatformTabs = () => (
    <div className="platform-tabs">
      {platforms.map((platformName) => (
        <button
          key={platformName}
          className={`platform-tab ${selectedPlatform === platformName ? 'active' : ''}`}
          onClick={() => handlePlatformChange(platformName)}
          disabled={isLoading || platformLoadingStates[platformName]}
        >
          {platformLoadingStates[platformName] ? (
            <div className="button-content">
              <span className="loading-spinner" />
              <span>{platformName}</span>
            </div>
          ) : (
            <div className="button-content">
              <span>{platformName}</span>
            </div>
          )}
        </button>
      ))}
    </div>
  );

  const renderStatus = (status) => {
    if (!status) {
      return <span className="status-badge status-unknown">Unknown</span>;
    }
    return <span className={`status-badge status-${status.toLowerCase()}`}>{status}</span>;
  };

  const applyFilters = useCallback((ordersToFilter) => {
    if (!Array.isArray(ordersToFilter)) return;

    let filteredOrders = ordersToFilter;

    if (statusFilter) {
      filteredOrders = filteredOrders.filter(order => 
        order.STATUS?.toLowerCase() === statusFilter.toLowerCase()
      );
    }

    if (poNumberSearch) {
      filteredOrders = filteredOrders.filter(order => 
        order.PoNumber?.toString().toLowerCase().includes(poNumberSearch.toLowerCase())
      );
    }

    if (startDate && endDate) {
      const start = new Date(startDate.setHours(0, 0, 0, 0));
      const end = new Date(endDate.setHours(23, 59, 59, 999));
      
      filteredOrders = filteredOrders.filter(order => {
        if (!order.PoDate) return false;
        const poDate = new Date(order.PoDate);
        return poDate >= start && poDate <= end;
      });
    }

    setOrders(filteredOrders);
    setTotalPages(Math.ceil(filteredOrders.length / recordsPerPage));
    setCurrentPage(1);
  }, [statusFilter, poNumberSearch, startDate, endDate, recordsPerPage]);

  useEffect(() => {
    loadOrders(selectedPlatform);
  }, [loadOrders, selectedPlatform]);

  useEffect(() => {
    applyFilters(allOrders);
  }, [applyFilters, allOrders, statusFilter, poNumberSearch, startDate, endDate]);

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handlePoNumberSearchChange = (e) => {
    debouncedPoNumberSearch.search(e.target.value);
  };

  const handleDateChange = (update) => {
    try {
      const [start, end] = update;
      if (start && end && start > end) {
        setAlert({
          type: 'warning',
          message: 'Start date cannot be after end date',
          title: 'Invalid Date Range'
        });
        return;
      }
      setStartDate(start);
      setEndDate(end);
    } catch (error) {
      console.error('Error handling date change:', error);
      setAlert({
        type: 'error',
        message: 'Error processing date range',
        title: 'Date Error'
      });
    }
  };

  const toggleRow = async (poNumber) => {
    if (activeToggle === poNumber) {
      setActiveToggle(null);
    } else {
      setActiveToggle(poNumber);
      try {
        if (!toppledData[poNumber]) {
          const response = await fetchPodata(poNumber);
          
          // Handle different response data structures
          let validData = [];
          if (response?.data) {
            if (Array.isArray(response.data)) {
              // Flatten nested arrays if any
              validData = response.data.flat();
            } else if (typeof response.data === 'object') {
              // If it's a single object, wrap it in an array
              validData = [response.data];
            }
          }
          
          setToppledData(prevState => ({
            ...prevState,
            [poNumber]: validData
          }));
        }
      } catch (error) {
        console.error('Error loading toppled data:', error);
        setAlert({
          type: 'error',
          message: 'Failed to load order details. Please try again.',
          title: 'Error'
        });
      }
    }
  };

  const toggleSelectAll = () => {
    const newState = !selectAll;
    setOrders(prevOrders => prevOrders.map(order => ({ ...order, selected: newState })));
    setSelectAll(newState);
  };

  const handleCheckboxChange = (index) => {
    if (typeof index !== 'number' || index < 0 || index >= orders.length) {
      console.error('Invalid index for checkbox change');
      return;
    }

    setOrders(prevOrders => {
      const updatedOrders = [...prevOrders];
      updatedOrders[index] = {
        ...updatedOrders[index],
        selected: !updatedOrders[index].selected
      };
      
      // Update selectAll state based on all checkboxes
      const allSelected = updatedOrders.every(order => order.selected);
      setSelectAll(allSelected);
      
      return updatedOrders;
    });
  };

  const exportToExcel = () => {
    const selectedOrders = orders.filter(order => order.selected);
    if (selectedOrders.length === 0) {
      alert('Please select at least one order to export.');
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(selectedOrders);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveExcelFile(excelBuffer, 'exported_data.xlsx');
  };

  const saveExcelFile = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  };

  // eslint-disable-next-line no-unused-vars
  const handleFileUpload = () => {
    // Implementation here
  };

  // eslint-disable-next-line no-unused-vars
  const handleEditClick = () => {
    // Implementation here
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    return date.toLocaleDateString();
  };

  const renderCellContent = (value, column) => {
    if (value === null || value === undefined || value === '') {
      return '';
    }
    
    try {
      // Handle date formatting
      if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}/.test(value)) {
        const date = new Date(value);
        return !isNaN(date.getTime()) ? formatDate(value) : value;
      }
      
      // Handle currency formatting
      if (column === 'PoValues') {
        const number = parseFloat(value);
        return !isNaN(number) ? 
          new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' })
            .format(number) : '';
      }
      
      // Handle weight formatting
      if (['Tonnage', 'Moq'].includes(column)) {
        const number = parseFloat(value);
        return !isNaN(number) ? `${Math.round(number)} KG` : '';
      }
      
      // For other numeric values
      if (typeof value === 'number' && !isNaN(value)) {
        return Math.round(value);
      }
      
      // For objects
      if (typeof value === 'object' && value !== null) {
        return JSON.stringify(value);
      }
      
      return value;
    } catch (error) {
      console.error(`Error rendering cell content for ${column}:`, error);
      return String(value);
    }
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditingItem(null);
  };

  const handleSaveEdit = async () => {
    if (!editingItem) return;
    
    try {
      setIsLoading(true);
      
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.user_id) {
        throw new Error('User authentication required');
      }

      // Validate required fields
      if (!editingItem.Price || !editingItem.Quantity) {
        throw new Error('Price and Quantity are required fields');
      }

      // Ensure values are numbers
      const newPrice = parseFloat(editingItem.Price);
      const newQuantity = parseFloat(editingItem.Quantity);

      if (isNaN(newPrice) || isNaN(newQuantity)) {
        throw new Error('Price and Quantity must be valid numbers');
      }

      // Add timestamp to new remarks if present
      const timestamp = new Date().toLocaleString();
      const newRemarkWithTimestamp = editingItem.newRemarks 
        ? `[${timestamp}] ${editingItem.newRemarks}`
        : '';

      // Combine existing and new remarks
      const combinedRemarks = editingItem.Remarks
        ? (newRemarkWithTimestamp ? `${editingItem.Remarks}\n${newRemarkWithTimestamp}` : editingItem.Remarks)
        : newRemarkWithTimestamp;

      const updateData = {
        newPrice,
        newQuantity,
        remarks: combinedRemarks,
        userId: user.user_id,
        userName: `${user.first_name} ${user.last_name}`
      };

      const result = await editOrderItem(
        editingItem.PoNumber,
        editingItem.ItemId,
        updateData
      );

      if (result.success) {
        // Update local state
        setToppledData(prevState => ({
          ...prevState,
          [editingItem.PoNumber]: result.data
        }));

        // Refresh the orders
        await loadOrders(selectedPlatform);
        
        setAlert({
          type: 'success',
          message: 'Item updated successfully',
          title: 'Success'
        });
        
        handleCloseEditModal();
      } else {
        throw new Error(result.message || 'Failed to update item');
      }
      
    } catch (error) {
      console.error('Error updating item:', error);
      setAlert({
        type: 'error',
        message: error.message || 'Failed to update item. Please try again.',
        title: 'Update Failed'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditItemChange = (field, value) => {
    if (!editingItem) return;
    
    let processedValue = value;
    
    // Validate numeric fields
    if (field === 'Price' || field === 'Quantity') {
      const numValue = parseFloat(value);
      if (isNaN(numValue) || numValue < 0) return;
      processedValue = numValue;
    }

    setEditingItem(prevItem => ({
      ...prevItem,
      [field]: processedValue
    }));
  };

  const LoadingOverlay = () => {
    if (!isLoading) return null;
    
    return (
      <div className="loading-overlay">
        <div className="loading-spinner" />
      </div>
    );
  };

  const paginate = (pageNumber) => {
    const maxPage = Math.max(1, totalPages);
    const validPageNumber = Math.min(Math.max(1, pageNumber), maxPage);
    setCurrentPage(validPageNumber);
  };

  const getCurrentRecords = useCallback(() => {
    if (!Array.isArray(orders)) return [];
    
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    return orders.slice(indexOfFirstRecord, indexOfLastRecord);
  }, [currentPage, recordsPerPage, orders]);

  const renderPagination = () => {
    const pageNumbers = [];
    const maxPagesToShow = 4;
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="pagination-container">
        <div className="pagination-info">
          Showing {Math.min(currentPage * recordsPerPage, orders.length)} of {orders.length} entries
        </div>
        <div className="pagination-controls">
          <button 
            className="btn btn-sm btn-outline-primary" 
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {pageNumbers.map(page => (
            <button
              key={page}
              className={`btn btn-sm ${currentPage === page ? 'btn-primary' : 'btn-outline-primary'}`}
              onClick={() => paginate(page)}
            >
              {page}
            </button>
          ))}
          <button 
            className="btn btn-sm btn-outline-primary" 
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
          <select
            className="form-select form-select-sm"
            value={currentPage}
            onChange={(e) => paginate(Number(e.target.value))}
          >
            {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
              <option key={page} value={page}>
                Page {page}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  const handleApproveOrders = async () => {
    const selectedOrders = orders.filter(order => order.selected);
    
    if (selectedOrders.length === 0) {
      setAlert({
        type: 'warning',
        message: 'Please select an order to approve.',
        title: 'No Order Selected'
      });
      return;
    }
  
    if (selectedOrders.length > 1) {
      setAlert({
        type: 'warning',
        message: 'Please select only one order at a time for approval.',
        title: 'Multiple Orders Selected'
      });
      return;
    }

    const selectedOrder = selectedOrders[0];

    // Check if order is already approved
    if (selectedOrder.STATUS && selectedOrder.STATUS.toLowerCase() === 'approved') {
      setAlert({
        type: 'info',
        message: `PO Number ${selectedOrder.PoNumber} is already approved. No further action needed.`,
        title: 'Already Approved'
      });
      return;
    }

    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || !user.user_id) {
        setAlert({
            type: 'error',
            message: 'User authentication required',
            title: 'Authentication Error'
        });
        return;
    }

    if (selectedOrder.Remarks) {
      const confirmMessage = `Are you sure you want to approve this order?\n\nPO Number: ${selectedOrder.PoNumber}\nRemarks: ${selectedOrder.Remarks}`;
      
      setAlert({
        type: 'warning',
        message: confirmMessage,
        title: 'Confirm Approval',
        onConfirm: () => processApproval(selectedOrders, user, selectedPlatform),
        showConfirmButton: true
      });
      return;
    }

    await processApproval(selectedOrders, user, selectedPlatform);
  };

  const processApproval = async (selectedOrders, user, platform) => {
    try {
        setIsLoading(true);
        
        const orderData = {
            orders: selectedOrders.map(order => ({
                PoNumber: order.PoNumber,
                Platform: platform,
                Status: order.STATUS,
                ItemId: order.ItemId,
                Price: order.Price,
                Quantity: order.Quantity,
                Remarks: order.Remarks || ''
            })),
            userId: user.user_id,
            userName: `${user.first_name} ${user.last_name}`,
            platform: platform
        };

        const result = await approveOrders(orderData);
        
        if (result.success) {
            await loadOrders(platform);
            setSelectAll(false);
            
            setAlert({
                type: 'success',
                message: `Successfully approved ${selectedOrders.length} order${selectedOrders.length > 1 ? 's' : ''}.`,
                title: 'Orders Approved'
            });
        } else {
            throw new Error(result.message || 'Failed to approve orders');
        }
    } catch (error) {
        console.error('Error approving orders:', error);
        setAlert({
            type: 'error',
            message: error.message || 'Failed to approve orders. Please try again.',
            title: 'Approval Failed'
        });
    } finally {
        setIsLoading(false);
    }
  };

  const renderToppledData = (orderNumber) => {
    // Early return if no order number
    if (!orderNumber) return null;

    // Get data for this order and handle undefined/null cases
    const data = toppledData[orderNumber] || [];
    
    // If no data or empty array, show loading or empty state
    if (!Array.isArray(data) || data.length === 0) {
      return (
        <div className="toppled-data">
          <h6 className="toppled-data-title">Order Details</h6>
          <div className="toppled-table-container">
            <p className="text-center text-muted">
              {isLoading ? 'Loading...' : 'No details available'}
            </p>
          </div>
        </div>
      );
    }

    // Get column names from the first item
    const columnNames = Object.keys(data[0] || {});

    // If no columns, show error state
    if (columnNames.length === 0) {
      return (
        <div className="toppled-data">
          <h6 className="toppled-data-title">Order Details</h6>
          <div className="toppled-table-container">
            <p className="text-center text-muted">Invalid data format</p>
          </div>
        </div>
      );
    }

    return (
      <div className="toppled-data">
        <h6 className="toppled-data-title">Order Details</h6>
        <div className="toppled-table-container">
          <table className="toppled-table">
            <thead>
              <tr>
                {columnNames.map((columnName, index) => (
                  <th key={index}>{columnName}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, rowIndex) => (
                <tr key={rowIndex}>
                  {columnNames.map((columnName, cellIndex) => (
                    <td key={cellIndex}>
                      {renderCellContent(item[columnName], columnName)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderEditModalContent = () => {
    if (!editingItem) return null;
    
    return (
      <div className="edit-form">
        {Object.entries(editingItem).map(([key, value]) => {
          if (key === 'selected' || key === 'newRemarks') return null;
          
          const isEditable = ['Price', 'Quantity'].includes(key);
          
          if (key === 'Remarks') {
            return (
              <div className="form-group" key={key}>
                <label>Previous Remarks</label>
                <textarea
                  className="form-control"
                  value={value || ''}
                  disabled={true}
                  rows={4}
                />
                <label className="mt-3">Add New Remark</label>
                <textarea
                  className="form-control"
                  value={editingItem.newRemarks || ''}
                  onChange={(e) => handleEditItemChange('newRemarks', e.target.value)}
                  rows={4}
                  placeholder="Add new remark here..."
                />
              </div>
            );
          }

          return (
            <div className="form-group" key={key}>
              <label>{key}</label>
              <input
                type={['Price', 'Quantity'].includes(key) ? 'number' : 'text'}
                className="form-control"
                value={value || ''}
                onChange={(e) => handleEditItemChange(key, e.target.value)}
                disabled={!isEditable}
              />
            </div>
          );
        })}
        
        <div className="modal-footer">
          <button className="btn btn-secondary" onClick={handleCloseEditModal}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleSaveEdit}>
            Save Changes
          </button>
        </div>
      </div>
    );
  };

  const debouncedPoNumberSearch = useMemo(() => {
    const debounced = debounce((value) => {
      setPoNumberSearch(value);
    }, 300);
    
    return {
      search: debounced,
      cancel: debounced.cancel
    };
  }, []);

  useEffect(() => {
    return () => debouncedPoNumberSearch.cancel();
  }, [debouncedPoNumberSearch]);

  const tableRef = useRef(null);

  // Update the scroll effect
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = scrollableRef.current.scrollTop;
      const scrollDelta = scrollTop - lastScrollTop;
      const wrapper = scrollableRef.current;

      // Only apply classes if we've scrolled more than 5px (reduced from 10px for smoother experience)
      if (Math.abs(scrollDelta) > 5) {
        if (scrollDelta > 0 && scrollTop > 50) { // Reduced threshold from 100 to 50
          // Scrolling down and past the threshold
          wrapper.classList.add('scroll-up');
        } else if (scrollDelta < 0) {
          // Scrolling up
          wrapper.classList.remove('scroll-up');
        }
        
        setLastScrollTop(scrollTop);
      }
    };

    const scrollableWrapper = scrollableRef.current;
    if (scrollableWrapper) {
      scrollableWrapper.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableWrapper) {
        scrollableWrapper.removeEventListener('scroll', handleScroll);
      }
    };
  }, [lastScrollTop]);

  return (
    <div className="po-table-container">
      <div className="table-scroll-container">
        <div className="scrollable-wrapper" ref={scrollableRef}>
          {renderPlatformTabs()}
          <div className="filters-card">
            <div className="filters-content">
              <div className="filters-row">
                <div className="filter-item">
                  <div className="custom-filter-group">
                    <div className="filter-label">Status</div>
                    <select 
                      id="statusFilter"
                      value={statusFilter} 
                      onChange={handleStatusFilterChange}
                      className="custom-select"
                    >
                      <option value="">All Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Approved">Approved</option>
                      <option value="Shipped">Shipped</option>
                      <option value="Delivered">Delivered</option>
                      <option value="Canceled">Canceled</option>
                      <option value="Unknown">Unknown</option>
                    </select>
                  </div>
                </div>
                
                <div className="filter-item">
                  <div className="custom-filter-group">
                    <div className="filter-label">Search</div>
                    <div className="search-input-wrapper">
                      <Search size={12} className="search-icon" />
                      <input 
                        id="poSearch"
                        type="text" 
                        className="custom-input" 
                        placeholder="Search PO Number..." 
                        value={poNumberSearch}
                        onChange={handlePoNumberSearchChange}
                      />
                    </div>
                  </div>
                </div>
                
                <div className="filter-item">
                  <div className="custom-filter-group">
                    <div className="filter-label">Date</div>
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={handleDateChange}
                      placeholderText="Select date range"
                      className="custom-input date-picker"
                      wrapperClassName="date-picker-wrapper"
                    />
                  </div>
                </div>
              </div>
            </div>
            
            <div className="actions-row">
              <div className="action-item">
                <button className="btn btn-sm btn-primary" onClick={loadOrders} title="Load Orders">
                  <RefreshCw size={14} className="me-1" />
                  <span>Load</span>
                </button>
              </div>
              <div className="action-item">
                <button className="btn btn-sm btn-secondary" title="Hard Refresh" onClick={() => window.location.reload()}>
                  <RotateCw size={14} className="me-1" />
                  <span>Refresh</span>
                </button>
              </div>
              <div className="action-item">
                <button 
                  className="btn btn-sm btn-success" 
                  title="Approve Orders"
                  onClick={handleApproveOrders}
                  disabled={isLoading}
                >
                  <Check size={14} className="me-1" />
                  <span>{isLoading ? 'Processing...' : 'Approve'}</span>
                </button>
              </div>
              <div className="action-item">
                <button className="btn btn-sm btn-dark" onClick={exportToExcel} title="Export to Excel">
                  <Download size={14} className="me-1" />
                  <span>Export</span>
                </button>
              </div>
              <div className="action-item">
                <button 
                  className="btn btn-sm btn-outline-danger" 
                  onClick={() => {
                    setStatusFilter('');
                    setPoNumberSearch('');
                    setStartDate(null);
                    setEndDate(null);
                    setSelectAll(false);
                    setOrders(orders.map(order => ({ ...order, selected: false })));
                  }}
                  title="Clear All Filters"
                >
                  <X size={14} className="me-1" />
                  <span>Clear All</span>
                </button>
              </div>
            </div>
          </div>

          <div className="table-wrapper">
            <div ref={tableRef} className="table-scroll">
              <LoadingOverlay />
              <div className="table-responsive">
                <div className="table-scroll">
                  <table className="table" role="table">
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectAll}
                            onChange={toggleSelectAll}
                          />
                        </th>
                        <th></th>
                        {columns.map((column, index) => (
                          <th key={index}>{column}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {getCurrentRecords().map((order, displayIndex) => (
                        <React.Fragment key={order.PoNumber || displayIndex}>
                          <tr className={activeToggle === order.PoNumber ? 'active-row' : ''}>
                            <td>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={order.selected}
                                onChange={() => handleCheckboxChange(displayIndex)}
                              />
                            </td>
                            <td>
                              <button 
                                className="btn btn-sm btn-link p-0 toggle-btn"
                                onClick={() => toggleRow(order.PoNumber)}
                              >
                                {activeToggle === order.PoNumber ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
                              </button>
                            </td>
                            {columns.map((column, colIndex) => (
                              <td key={colIndex}>
                                {column === 'STATUS' ? 
                                  renderStatus(order[column] || 'Pending') : 
                                  renderCellContent(order[column], column)
                                }
                              </td>
                            ))}
                          </tr>
                          {activeToggle === order.PoNumber && (
                            <tr className="details-row">
                              <td colSpan={columns.length + 2}>
                                {renderToppledData(order.PoNumber)}
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {renderPagination()}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showEditModal} onClose={handleCloseEditModal}>
        {renderEditModalContent()}
      </Modal>
      {alert && (
        <div className="dialog-container">
          <AlertCard
            type={alert.type}
            message={alert.message}
            title={alert.title}
            onClose={() => setAlert(null)}
            onConfirm={alert.onConfirm}
            showConfirmButton={alert.showConfirmButton}
          />
        </div>
      )}
    </div>
  );
};

export default PoTable;
              